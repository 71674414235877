<template>
    <div id="avaliacaoDosDocumentos">
        <div>
            <b-card class="card">

                <h3 style="color: brown">Controle de Documentações</h3>

                <br>

                <div>
                    <b-jumbotron style="background-color: #F9F9F9">

                        <b-row style="margin-top: -25px">

                            <div class="col-lg-4">
                                <h3>Dados Pessoais</h3>
                            </div>

                            <div class="col-lg-4 centralizado">
                                <b-avatar :src="form.url_foto_perfil" size="100"></b-avatar>
                            </div>

                            <div class="col-lg-4">
                                <b-button 
                                variant="warning" 
                                class="botao-voltar"
                                @click="toDocsPendentes"
                                >Voltar
                                </b-button>
                            </div>

                        </b-row>

                        <br>
                        <br>

                        <b-row>

                            <div class="col-lg-6 form-group">
                                <label><span class="text-danger">*</span> Nome</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="form.nome" 
                                ></b-form-input>
                            </div>

                            <div class="col-lg-6 form-group">
                                <label><span class="text-danger">*</span> Situação</label>
                                <b-form-input 
                                readonly
                                v-model="form.status"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> Situação na cooperativa</label>
                                <b-form-input 
                                readonly
                                v-model="form.cooperativa"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> Matricula</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="form.matricula"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> CPF</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="form.cpf"
                                ></b-form-input>
                            </div>

                            <div class="col-lg-3 form-group">
                                <label><span class="text-danger">*</span> Data Nascimento</label>
                                <b-form-input 
                                readonly
                                class="text-uppercase"
                                v-model="form.data_nascimento"
                                ></b-form-input>
                            </div>

                        </b-row>

                        <div id="foto-perfil" v-if="this.possuiFotoDePerfil == true">

                            <hr style="background-color: red">

                            <h3>Foto de Perfil</h3>

                            <br>

                            <b-row>

                                <div class="col-lg-3 form-group">
                                    <b-img 
                                    :src="fotoPerfil" 
                                    ></b-img>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                                <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="fotoDePerfil.avaliacao" 
                                    name="fotoPerfil" 
                                    value="Aprovado"
                                    @change="avaliarDocumento"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="fotoDePerfil.avaliacao" 
                                    name="fotoPerfil" 
                                    value="Reprovado"
                                    @change="avaliarDocumento"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="fotoDePerfil.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="fotoDePerfil.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="fotoDePerfil.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeFotoPerfil" 
                                        v-model="fotoDePerfil.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>
                        </div>


                        <div id="atestado" v-if="this.possuiAtestado == true">

                            <hr style="background-color: red">

                            <h3>Atestado</h3>

                            <br>

                            <b-row>

                                <div class="col-lg-3 form-group">
                                    <b-img 
                                    :src="fotoAtestado" 
                                    ></b-img>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                                <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="atestadoMedico.avaliacao" 
                                    name="atestadoMedico" 
                                    value="Aprovado"
                                    @change="avaliarDocumento"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="atestadoMedico.avaliacao" 
                                    name="atestadoMedico" 
                                    value="Reprovado"
                                    @change="avaliarDocumento"   
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoMedico.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="atestadoMedico.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoMedico.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeAtestado" 
                                        v-model="atestadoMedico.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>
                        </div>


                        <div id="antecedentes-criminais" v-if="this.possuiAntecedentesCriminais == true">

                            <hr style="background-color: red">

                            <h3>Antecedentes Criminais</h3>

                            <br>

                            <b-row>

                                <div class="col-lg-3 form-group">
                                    <b-img 
                                    :src="fotoAntecedentes" 
                                    ></b-img>
                                </div>

                                <div class="col-lg-3 form-group">
                                    <b-img 
                                    :src="fotoAntecedentes" 
                                    ></b-img>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                                <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="atestadoAntecedentes.avaliacao" 
                                    name="atestadoAntecedentes" 
                                    value="Aprovado"
                                    @change="avaliarDocumento"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="atestadoAntecedentes.avaliacao" 
                                    name="atestadoAntecedentes" 
                                    value="Reprovado"
                                    @change="avaliarDocumento"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoAntecedentes.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="atestadoAntecedentes.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="atestadoAntecedentes.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeAntecedentes" 
                                        v-model="atestadoAntecedentes.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>

                        </div>


                        <div id="uniforme" v-if="this.possuiUniforme == true">

                            <hr style="background-color: red">

                            <br>

                            <h3>Uniforme</h3>

                            <br>

                            <b-row>

                                <div class="col-lg-3 form-group">
                                    <b-img 
                                    :src="fotoUniforme" 
                                    ></b-img>
                                </div>

                            </b-row>

                            <br>

                            <b-row class="avaliacao-documento">

                            <div class="form-check col-lg-1 form-group">
                                    <b-form-radio 
                                    v-model="uniforme.avaliacao" 
                                    name="uniforme" 
                                    value="Aprovado"
                                    @change="avaliarDocumento"
                                    >Aprovado</b-form-radio>
                                </div>

                                <div class="form-check col-lg-2 form-group">
                                    <b-form-radio 
                                    v-model="uniforme.avaliacao" 
                                    name="uniforme" 
                                    value="Reprovado"
                                    @change="avaliarDocumento"
                                    >Reprovado</b-form-radio>
                                </div>

                                <div class="col-lg-2 form-group" v-if="uniforme.avaliacao == 'Reprovado'">
                                    <label><span class="text-danger"></span> Motivo: </label>
                                    <b-form-select :options="motivos"
                                    v-model="uniforme.motivoReprovacao"
                                    ></b-form-select>
                                </div>

                                <div class="col-lg-2 form-group" v-if="uniforme.avaliacao == 'Aprovado'">
                                    <label><span class="text-danger"></span> Validade: </label>
                                    <b-form-datepicker id="validadeUniforme" 
                                        v-model="uniforme.dataValidade" 
                                        placeholder="__/__/____"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                    </b-form-datepicker>
                                </div>

                            </b-row>

                        </div>

                        <hr style="background-color: red">

                        <br>

                        <b-row class="botoes-centralizados">

                            <div class="col-lg-2 form-group">
                                <b-button variant="success" size="lg" @click="confirmar">
                                    <b-icon-check2></b-icon-check2>
                                    Confirmar
                                </b-button>
                            </div>

                        </b-row>

                    </b-jumbotron>
                </div>

            </b-card>
        </div>
    </div>
</template>

<script>

import api from "../../services/api";
import dayjs from "dayjs"

export default {

  name: 'AvaliacaoDocs',

  data() {
    return {

        form: {},

        fotoDePerfil: { 
            id: null,
            tipo: 'fotoPerfil',
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null
        },
        atestadoMedico: { 
            id: null,
            tipo: 'atestadoMedico',
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null 
        },
        atestadoAntecedentes: {
            id: null,
            tipo: 'atestadoAntecedentes', 
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null 
        },
        uniforme: { 
            id: null,
            tipo: 'uniforme',
            avaliacao: null, 
            motivoReprovacao: null, 
            dataValidade: null 
        },

        possuiFotoDePerfil: false,
        possuiAtestado: false,
        possuiAntecedentesCriminais: false,
        possuiUniforme: false,

        fotoPerfil: null,
        fotoAtestado: null,
        fotoAntecedentes: null,
        fotoUniforme: null,

        motivos: [
            'A foto não está nítida',
            'O documento está danificado',
            'O documento está fora de validade',
            'Os dados do cooperado no documento não condizem com as credenciais do sistema'
        ],

        documentosEnviados: 0,
        documentosAvaliados: 0,

        avaliacaoDocumentos: [],
        avaliacoesConcluidas: 0

    };
  },

  created() {
    this.getCooperado()

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    toDocsPendentes() {
        this.$router.push('/coopDocsPendentes')
    },

    getCooperado() {
        const cooperado = localStorage.getItem("cooperadoDocsAvaliacao")
        this.form = JSON.parse(cooperado)

        const docsAvaliacao = this.form.docsParaAvaliacao

        for (let i = 0; i < docsAvaliacao.length; i++) {

            if (docsAvaliacao[i].tipo == 'fotoPerfil') {

                this.possuiFotoDePerfil = true
                this.fotoPerfil = docsAvaliacao[i].urlDocumento
                this.documentosEnviados++
            } else 

            if (docsAvaliacao[i].tipo == 'atestadoMedico') {

                this.possuiAtestado = true
                this.fotoAtestado = docsAvaliacao[i].urlDocumento
                this.documentosEnviados++
            } else 

            if (docsAvaliacao[i].tipo == 'atestadoAntecedentes') {

                this.possuiAntecedentesCriminais = true
                this.fotoAntecedentes = docsAvaliacao[i].urlDocumento
                this.documentosEnviados++
            } else {
                
                this.possuiUniforme = true
                this.fotoUniforme = docsAvaliacao[i].urlDocumento
                this.documentosEnviados++
            }

            if (docsAvaliacao[i].tipo == this.fotoDePerfil.tipo) this.fotoDePerfil.id = docsAvaliacao[i].id
            else

            if (docsAvaliacao[i].tipo == this.atestadoMedico.tipo) this.atestadoMedico.id = docsAvaliacao[i].id
            else

            if (docsAvaliacao[i].tipo == this.atestadoAntecedentes.tipo) this.atestadoAntecedentes.id = docsAvaliacao[i].id
            else

            this.uniforme.id = docsAvaliacao[i].id
        }

    },

    avaliarDocumento() {
        this.documentosAvaliados++
    },

    confirmar() {
        if (this.documentosAvaliados >= this.documentosEnviados) {

            this.avaliacaoDocumentos = []
            this.avaliacoesConcluidas = 0

            const documentosDaPagina = [
                this.possuiFotoDePerfil, this.possuiAtestado, 
                this.possuiAntecedentesCriminais, this.possuiUniforme
            ]

            const avaliacoes = [
                this.fotoDePerfil, this.atestadoMedico,
                this.atestadoAntecedentes, this.uniforme
            ]

            for (let i = 0; i < documentosDaPagina.length; i++) {
                if (documentosDaPagina[i]) this.avaliacaoDocumentos.push(avaliacoes[i])
            }

            const dataHoje = dayjs(new Date()).format('DD/MM/YYYY')

            for (let i = 0; i < this.avaliacaoDocumentos.length; i++) {
                if (this.avaliacaoDocumentos[i].avaliacao == 'Aprovado' && this.avaliacaoDocumentos[i].dataValidade == null) {
                    this.msg("Atenção", "Você deve selecionar uma data de vencimento.", "danger")
                } else 

                if (this.avaliacaoDocumentos[i].avaliacao == 'Aprovado' && dayjs(this.avaliacaoDocumentos[i].dataValidade).format('DD/MM/YYYY') < dataHoje) {
                    this.msg("Atenção", "A data de vencimento selecionada não é válida.", "danger")
                } else 

                if (this.avaliacaoDocumentos[i].avaliacao == 'Reprovado' && this.avaliacaoDocumentos[i].motivoReprovacao == null) {
                    this.msg("Atenção", "Você deve justificar a sua reprovação.", "danger")
                } else {
                    this.avaliacoesConcluidas++
                }
            }

            if (this.avaliacoesConcluidas == this.documentosEnviados) {

                api.put(`webadmin/coopDocsAvaliacao`, this.avaliacaoDocumentos)
                .then(() => {

                    this.$router.push('/coopDocsPendentes')
                })
                .catch(err => this.msg(err.response.data, 'vermelho', 5000))
            }
        }
        
        else
        this.msg("Atenção", "Todos os documentos devem ser avaliados", "danger")
        
    }

  }

}

</script>

<style scoped>

    .card{
        margin: 40px;
    }

    .centralizado{
       text-align: center;
    }

    .botao-voltar{
        float: right;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .checkbox-e-select {
        margin-top: 500px;
    }

    .botoes-centralizados {
        justify-content: center;
        align-items: center;
    }

    .avaliacao-documento {
        justify-content: right;
    }

</style>