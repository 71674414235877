<template>
 
  <div class="page">
      
      <b-row> 
        <b-col sm="11" >
          <h2>Preparação Faturamento</h2>
       </b-col>
        <b-col sm="1" >
          <b-button class="btnRight" variant="btn btn-outline-success btn-sm" link to="/"> Voltar</b-button>
        </b-col>
      </b-row> 
      
      <hr>

      <form   @reset="onReset" v-if="show" >

        <!-- FILTROS DA TELA -->

        <b-row> 
          <b-col sm="6" style="display:flex;">
            <b-col sm="4">
              <b-form-group class="form-listbox"  label="Cliente">
                  <b-form-select class="filtro-item" :options="clientes" v-model="filtro.idCliente" @change="getUnidades"></b-form-select> 
              </b-form-group> 
            </b-col>

            <b-col sm="4">
              <b-form-group class="form-listbox" label="Unidade">
                  <b-form-select class="filtro-item" :options="unidades" v-model="filtro.idUnidade" @change="getSetores"></b-form-select> 
              </b-form-group> 
            </b-col>

            <b-col sm="4">
              <b-form-group class="form-listbox" label="Setor">
                  <b-form-select class="filtro-item" :options="setores" v-model="filtro.idSetor" @change="getFaturamentos"></b-form-select> 
              </b-form-group>
            </b-col>
          </b-col>


           

          <b-col sm="6" style="display:flex;">
            <b-col sm="5">
              <b-form-group class="form-listbox" label="Periodo" label-for="input-periodo">
                <date-picker  
                    id="input-periodo"
                    v-model="range"
                    lang="pt-br" 
                    range
                    style="width:220px;"
                    type="date"
                    format="DD/MM/YYYY"
                    width="300px"
                    @change="getFaturamentos"
                >
                         
                </date-picker>
        
              </b-form-group>
            </b-col>

            <b-col sm="7"> 
              <b-form-group label="Liberação" id="radio-list" >
                <b-form-radio-group  
                  @change="selecionaStatus"
                  class="filtro-item" 
                  id="radio-item"
                  v-model="filtro.status"
                  :options="fieldsStatus"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-form-group> 
            </b-col>
          </b-col> 
        </b-row>
          
        <!-- FIM - FILTROS DA TELA -->





        <!-- <b-card class="mt-3" header="Resultado do Form">
            <pre class="m-0">filtro.status: {{ filtro.status }}</pre>
            <pre class="m-0">range: {{ range }}</pre>
            <pre class="m-0">Form: {{ form }}</pre>
            <pre class="m-0">Unidades: {{ unidades }}</pre>
            <pre class="m-0">Setores: {{ setores }}</pre>
        </b-card> -->



        
        <hr>

        <b-jumbotron bg-variant="light"> 
 
            <p  class="tabela-titulo">Eventos</p>  
  
            <b-table    
              class="table"
              sticky-header="500px" 
              :items="items"   
              :fields="fields"  
              :busy="isBusy" 
              selectable    
              :select-mode="selectMode"
              ref="selectableTable"
              responsive="sm" 
              caption-top  
              :no-border-collapse="true" 
              @row-selected="onRowSelected"
            > 
              <!-- CARREGANDO -->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <!-- Example scoped slot for select state illustrative purposes -->
              <template #head(selected)="{ rowSelected }">
                <center>  
                  <template v-if="rowSelected">
                    <font-awesome-icon  class="ico-selected" icon="check-square" />  
                  </template>
                  <template v-else>
                    <font-awesome-icon  class="ico-unselected" icon="square" />  
                  </template>  
                </center>
              </template>  
              <template #cell(selected)="{ rowSelected }">
                <center>  
                  <template v-if="rowSelected">
                    <font-awesome-icon  class="ico-selected" icon="check-square" />  
                  </template>
                  <template v-else>
                    <font-awesome-icon  class="ico-unselected" icon="square" />  
                  </template>  
                </center>
              </template> 
              
              <template #cell(valorSolicitado)="row">
                <div class="valor">
                  {{ toCurrency(row.item.valorSolicitado) }}
                  </div>
              </template> 

              <template  #cell(valor)="row">
                <div class="valor"> 
                  {{ toCurrency(row.item.valor) }}
                </div>
              </template> 
            
              <template #cell(dataAprovacao)="row">
                  {{ formatDate(row.item.dataAprovacao) }}  
              </template>
        
            </b-table>
 
          <b-col sm="12" style="margin-top:20px;" >
            <b-button class="col-md-1 btnRight" type="button" @click="validarFaturamento" variant="success mb-3">Confirmar</b-button>
          </b-col>
        
  
        </b-jumbotron>
 
  
      </form>
  
  <div></div>
  </div>
</template>


<script>
import api from '../../../services/api';  

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css' 
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

import Dayjs from 'dayjs';
import br from 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

export default { 
  name: "Preparacao",
  components: { DatePicker },  
  data() {
    return {      

      range:[],

      clientes: [],
      unidades: [],
      setores: [],

      form: {
        idCliente: '0',
        idUnidade: '0',
        idSetor: '0',
      }, 

      filtro: {
        idCliente: '0',
        idUnidade: '0',
        idSetor: '0',
        status: 'aLiberar',
        dataIni: null,
        dataFim: null
      },

      // selected: { 
      //   cliente:[],
      //   unidade:[],
      //   setor:[], 
      //   start:'',
      //   end:'', 
      //   status:[] 
      // }, 

      
      fieldsStatus: [ 
        {text: "Não Aprovados", value: "naoAprovados"},
        {text: "A Liberar", value: "aLiberar"},
        {text: "Liberados", value: "liberados"},
      ],
 

      selectMode: 'multi',
      fields: [   
          { key: 'selected', label: 'selected' },
          { key: 'id', label: 'Id' , sortable: true},
          { key: 'cliente', label: 'Cliente' , sortable: true},
          { key: 'unidade', label: 'Unidade' , sortable: true},
          { key: 'setor', label: 'Setor' , sortable: true},
          { key: 'nomeEvento', label: 'Nome Evento', sortable: true },
          { key: 'nomeSolicitante', label: 'Solicitante', sortable: true },
          { key: 'valorSolicitado', label: 'Valor Solicitado', sortable: true },
          { key: 'valor', label: 'Valor Final', sortable: true },
          { key: 'dataAprovacao', label: 'Data Aprovação', sortable: true }, 
          { key: 'id_faturamento', label: 'Faturamento', sortable: true },
          { key: 'nota_fiscal', label: 'NF', sortable: true }   
        ], 
        
      items: [], 
      isBusy: false,

      gridSelected:[],
      faturamento:{}, 
      show: true
    };
  },  
  created() {
    
    this.getClientes();  
    
    var data = new Date();  
    data.setMonth( data.getMonth() -1 );
    this.range[0] = data; 
    this.range[1] = new Date(); 

    this.getFaturamentos()
  }, 
   
  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    //------------------------------------------------- 
    //FUNCOES DE TELA 

    selecionaStatus(){  
      // this.selectMode = null; 
      // if (this.selected.status == 'aLiberar') {
      //   this.selectMode = 'multi'
      // }
      
      this.getFaturamentos();  
    },

    //FIM FUNCOES DE TELA
    //------------------------------------------------- 
    //FUNCOES DE ACESSO API

    getClientes(){
      
      api.get(`/webadmin/clientes`).then(
        res => {

          // console.log(Object(res.data));
           const todos = [{ value: 0, text: 'TODOS'}]
           const clientes = res.data.map(item => {
              return {
                  value: item.id, 
                  text: item.nome_comercial 
              }  
          })
          
          this.clientes = [...todos, ...clientes]

        })
        .catch(err =>   console.log('Erro: Ao buscar Clientes.\n', err));
    },

    getUnidades(){

      this.unidades = []
      this.setores = []
       
      api.get(`/webadmin/clientes/unidades/${this.filtro.idCliente}`)
      .then(res => {
        //  console.log('unidades',res.data)
          const todos = [{ value: 0, text: 'TODOS'}]
          const unidades = res.data.map(item => {
              return {
                   value: item.id,
                   text: item.nome
              }
          })

        this.unidades = [...todos, ...unidades]
        this.getFaturamentos()

      })
      .catch(err =>   console.log('Erro: Ao buscar Eventos.\n', err)); 
    },
    
    getSetores(){
       
      api.get(`/webadmin/clientes/setores/unidade/${this.filtro.idUnidade}`)
      .then(res => {
        //  console.log('unidade',res.data)

          const todos = [{ value: 0, text: 'TODOS'}]
          const setores = res.data.map(item => {
            return {
                  value: item.id,
                  text: item.nome_setor
            }
          })

        this.setores = [...todos, ...setores]
        this.getFaturamentos()

      })
      .catch(err =>   console.log('Erro: Ao buscar Setores.\n', err)); 
    },

    getFaturamentos(){ 

     
      if(this.range[0] == null && this.range[1] == null) this.range = ['0', '0']

      const params = this.filtro
      const url = `/webadmin/faturamentos-preparacao/${params.idCliente}/${params.idUnidade}/${params.idSetor}/${params.status}/${this.range[0]}/${this.range[1]}` 
      // console.log('irl', url)
      
      api
        .get(url)
        .then(res => {
          console.log(url) 
          this.items = res.data;
          

          this.items.forEach(item => {
            
            if(item.id_usuario_cancelamento !== null) item._rowVariant = "row-disabled"
          })


        })
        .catch(err =>   console.log('Erro: Ao buscar Eventos.\n', err)); 

    },





    validarFaturamento() {   

      if(this.gridSelected.length == 0){
        this.msg("ATENÇÃO!", "Nenhum item foi selecionado!", "warning");
        return 
      } 

      const clienteListSelected = [...new Set(this.gridSelected.map(item => item.id_cliente))]
      if(clienteListSelected.length != 1){
        this.msg("ATENÇÃO!", "Nao é permitido criar um faturamento com clientes distintos!", "warning");
        return 
      } 

      const UnidadeListSelected = [...new Set(this.gridSelected.map(item => item.id_unidade))]
      if(UnidadeListSelected.length != 1){
        this.msg("ATENÇÃO!", "Nao é permitido criar um faturamento com unidades distintas!", "warning");
        return 
      } 

      this.preparaFaturamento(this.gridSelected);
      this.saveFaturamento()   

    },
    

    preparaFaturamento(eventos){
      
      // Lembrar de apagar
      //-----------------------------------------------
      const local = localStorage.getItem('userDados')
      const user = JSON.parse(local) 
      //-----------------------------------------------

      this.faturamento.ids_eventos = []
      this.faturamento.valor = 0;
 
      this.faturamento.data_periodo_inicio =  eventos[0].dataInicio;
      this.faturamento.data_periodo_fim =  eventos[0].dataInicio;
      
      for (let key in eventos){

        // console.log('key',key)

        this.faturamento.ids_eventos.push(eventos[key].id)
        this.faturamento.id_cliente = eventos[key].id_cliente;
        this.faturamento.id_unidade = eventos[key].id_unidade;
        this.faturamento.id_setor = eventos[key].id_setor;
        this.faturamento.setor = eventos[key].setor;
        this.faturamento.id_usuario_criacao = user.idUser; 


        const inicio = dayjs(this.faturamento.data_periodo_inicio);
        const fim = dayjs(this.faturamento.data_periodo_fim);
        const dataParaComparar = dayjs(eventos[key].dataInicio);

        if (dataParaComparar.isBefore(inicio))
          this.faturamento.data_periodo_inicio = eventos[key].dataInicio;
        else if (dataParaComparar.isBefore(fim))
          this.faturamento.data_periodo_fim = eventos[key].dataInicio;

        this.faturamento.status = 'gerarNF';
        this.faturamento.valor += Number(eventos[key].valor);
        this.faturamento.comentarios = "";

      } 
      
    },

    
    
    saveFaturamento(){

      api.post('webadmin/faturamentos-preparacao', this.faturamento)
      .then(() => {
 
        console.log('FATURAMENTO', 'CADASTRADO COM SUCESSO.', 'success');
        this.$router.push('/emissao'); 
        
      })
      .catch(err => console.log('ERRO AO CADASTRAR FATURAMENTO', err.response.data, 'danger'))

    },
    


    onReset(evt) {
      evt.preventDefault();  
    },

    //FIM DAS FUNCOES DE API
    //-------------------------------------------------
    //FUNCOES GRID
  

    //NOVAS
      onRowSelected(items) {
        this.gridSelected = items
      },

      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },

      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },


    customSort (value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }

        return b[sortBy].localeCompare(a[sortBy])
      })
    },

    customDisable() {

      if (this.selected.status == 'aLiberar'){
        return false;
      }

      return true;
    },
    formatDate(date){   
      console.log("Emissao -> [formatDate]")
      if (date == null || date == undefined) return null
      return Dayjs(date).locale(br).format('DD/MM/YYYY HH:mm') // '25/01/2019'
    }, 

    toCurrency(valor){   
        var formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }); 
        return formatter.format(valor); 
    }, 

    onSelect (items) {
        this.gridSelected = items
    },

    //FIM DAS FUNCOES DA GRID
    //-------------------------------------------------
 
  }    
};

</script>
 
<style scoped src="./Preparacao.css">  
</style>