<template>
  <div id="contestacoes" class="contorno">
      <h2 class="titulo2">Contestação</h2>
      <b-button class="btnRight" variant="warning" link to="/solicitacoes/verEscala">Voltar</b-button>
    <hr>
   
        <div class="col-lg-12">
            
            <b-jumbotron class="jumbotron">

                <b-row>

                    <div class="col-lg-6">                
                        <label><span></span>Solicitante</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.solicitante"></b-form-input>
                    </div>

                    <div class="col-lg-3">  
                        <label><span></span>Nome do Evento</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.nomeEvento"></b-form-input>
                    </div>

                    <div class="col-lg-3">  
                        <label><span></span>Setor</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.setor"></b-form-input>
                    </div>

                </b-row>

                <br>
                 <br>

                <b-row>
                    
                    <div class="col-lg-6">                
                        <label><span></span>Data</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.dataCriacao"></b-form-input>
                    </div>

                    <div class="col-lg-6">                
                        <label><span></span>Endereço</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.endereco"></b-form-input>
                    </div>

                </b-row> 

                <br>
                 <br>   

                <b-row>
                    
                    <div class="col-lg-6">
                        <label><span></span>Contestação</label>
                        <b-form-textarea 
                            disabled="true"
                            class="input-fundo"
                            rows="8"
                            v-model="topo.contestacao"
                        ></b-form-textarea>
                    </div>

                    <div class="col-lg-3">
                        <label><span></span>Valor solicitado total</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.valorSolicitado"></b-form-input>
                    </div>

                    <div class="col-lg-3">
                        <label><span></span>Valor total final</label>
                        <b-form-input disabled="true" class="input-fundo" v-model="topo.valorTotal"></b-form-input>
                    </div>

                </b-row>

            </b-jumbotron>

            <br>
             <br>

              <table class="table-responsive table-striped table col-lg-12">
                <thead>
                    <tr>
                        <th scope="col">Solicitante</th>
                        <th scope="col">Função</th>
                        <th scope="col">Qtd</th>
                        <th scope="col">Início</th>
                        <th scope="col">Saída</th>
                        <th scope="col">Carga Total</th>
                        <th scope="col">Gestor</th>
                        <th scope="col">Valor solicitado</th>
                        <th scope="col">Valor final</th>
                    </tr>
                </thead>

                <tbody>
                  <tr v-for="(escala) in topo.escalas" :key="escala.idEvento">
                    <!-- <td class="input-fundo"><b-form-checkbox
                                              @input="onPreviewClick($event, index, escala)"
                                              name="checkbox-1"
                                              value="true"
                                              unchecked-value="false"

                                              >
                                            </b-form-checkbox></td> -->

                    <td class="input-fundo">{{ escala.solicitante }}</td>
                    <td class="input-fundo">{{ escala.funcao }}</td>
                    <td class="input-fundo">{{ escala.quantidade }}</td>
                    <td class="input-fundo">{{ escala.inicio }}</td>
                    <td class="input-fundo">{{ escala.saida }}</td>
                    <td class="input-fundo">{{ escala.cargaTotal }}</td>
                    <td class="input-fundo">{{ escala.gestor }}</td>
                    <td class="input-fundo">{{ escala.valorSolicitado }}</td>
                    <td class="input-fundo">{{ escala.valorFinal }}</td>
                  </tr>
                </tbody>
            </table>

            <br>

            <div class="button-centralizado">
                <b-button variant="info" class="mr-2" @click="showModalResposta">
                    Responder Contestação
                </b-button>
            </div>
        
        </div>

        <div>
          
          <b-modal id="modal-resposta-contestacao" ref="modal-resposta-contestacao" hide-footer title="Responder Contestação">
            
            <div>
              <label><span></span>Escreva aqui a sua resposta: </label>
            </div>

            <br>
            <br>

            <div>
              <b-form-textarea
                class="text-area"
                rows="4"
                v-model="comentario"
              ></b-form-textarea>
            </div>

            <br>

            <b-row>
                
                <div class="col-lg-6">
                  <b-button class="mt-2" variant="success" block @click="enviarRespostaContestacao" link to="/solicitacoes">Enviar Resposta</b-button>
                </div>
                
                <div class="col-lg-6">
                  <b-button class="mt-2" variant="danger" block @click="hideModalResposta">Cancelar</b-button>
                </div>

            </b-row>

          </b-modal>

        </div>
    
    

  </div>
</template>

<script>

import api from "../../services/api";

export default {

  data(){
    return {
      topo: {},
      idsEscalas: [],
      comentario: null
    }
  },

  created(){
    this.getEventoContestacoes()
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    getEventoContestacoes(){

      const solicitacao = JSON.parse(localStorage.getItem('solicitacao'))
      
      api.get('webadmin/solicitacoes/contestacoes/'+solicitacao.idEvento)
        .then(res => {
          console.log('asasas',res.data)
        // this.cortesiaQtd = res.data
        this.topo = res.data
        
      })
      .catch(err => this.msg("ERRO AO BUSCAR A QUANTIDADE DA CORTESIA", err.response.data, "danger"));

    },

    showModalResposta(){
      if(this.topo.escalas.length == 0){
        this.msg('ATENÇÃO', 'Selecione ao menos uma escala', 'warning')
        return 
      }

      this.$refs['modal-resposta-contestacao'].show()
  
    },
    
    hideModalResposta(){
      
      this.$refs['modal-resposta-contestacao'].hide()
    },

    enviarRespostaContestacao(){

      const solicitacao = JSON.parse(localStorage.getItem('solicitacao'))

      // for(let i = 0; i < this.topo.escalas.length; i++){
      //     this.idsEscalas.push(this.topo.escalas[i].id)
      // }

      const idsEscalas = this.topo.escalas.map(item => {
        return item.id
      })

      api.post('webadmin/solicitacoes/contestacoes', {idEvento: solicitacao.idEvento, idEscalas: idsEscalas, mensagem: this.comentario})
        .then(res => {
          console.log('asasas',res.data)

      })
      .catch(err => this.msg("ERRO AO ENVIAR RESPOSTA", err.response.data, "danger"));



      if(idsEscalas.length > 0){
        this.msg('RESPOSTA ENVIADA', 'Sua reposta foi registrada com sucesso!', 'success')
        this.$refs['modal-resposta-contestacao'].hide()
      }
      
      
    },

    // onPreviewClick(event, index, escala) {
    //     // console.log(event, index)
    //     if(event == 'true') { 
    //       this.idsEscalas.push(escala.id)
    //       console.log(this.idsEscalas)
    //     } else {
    //       this.idsEscalas.splice(index, 1)
    //     }

    //     // console.log(this.idsEscalas)
    // },

  }

}
</script>

<style scoped>


h2 {
  color: black;
}

.jumbotron {
    border: solid 1px #ccc;
    background-color:  rgb(243, 243, 243);
    justify-content: center;
}

.titulo2 {
  display: flex;
  margin-top: 25px;
  margin-left: 20px;
  
}

.button-centralizado {
  text-align: center;
}

.contorno {
  border: solid 1px #ccc;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 12px;
}

.form-row {
  display: ruby;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.input-fundo{
  background-color: rgb(228, 228, 228);
  border-radius: 2px;
}

.text-area-modal-resposta{
  margin-top: -100px;
}

.table-responsive {
  display: table;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 576px) {
  .table-responsive {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
}

.table-striped tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 16px;
}

.text-hide{
  color: rgb(228, 228, 228);
  user-select: none;
}


</style>