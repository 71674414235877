<template>
  <div id="form-usuario">
    
    <b-card-group deck>

      <b-card
        bg-variant="light"
        text-variant="black"
        title="Módulo: Web Admin"
      >

        <div class="form-group col-lg-12 tamanho-padrao">
          <label><span class="text-danger"></span> Ativa / Desativa a senha mestra.</label>
          <b-form-input
            v-model="senhaMestraUltimoLogin"
          ></b-form-input>
        </div>

        <b-button :variant="corBotaoSenhaMestra" class="botao" @click="alterarStatusSenhaMestra()">{{labelBotaoSenhaMestra}}</b-button>
        
      </b-card>



      <b-card
        bg-variant="light"
        text-variant="black"
        title="Módulo: Web Cliente"
      >

        <div class="form-group col-lg-12 tamanho-padrao">
          <label><span class="text-danger"></span> Tempo limite para alterar a escala.</label>
          <b-form-input
            type="time"
            v-model="timeEscala"
          ></b-form-input>
        </div>

        <b-button variant="success" class="botao" @click="saveTimeAlterarEscala()">Confirmar</b-button>
        
      </b-card>
      
    </b-card-group>

    
  </div>
</template>


<script>
import api from "../../services/api";

export default {
  data() {
    return {

      timeEscala: null,
      statusSenhaMestra: null,
      senhaMestraUltimoLogin: null,
      labelBotaoSenhaMestra: '...',
      corBotaoSenhaMestra: 'success'

    };
  },

  created() {
  
    this.getConfig()
    this.trataBotaoSenhaMestra()

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    getConfig() {

      api.get('webadmin/admin/config')
      .then(res => {

        this.timeEscala = res.data.tempo_limite_editar_escala
        this.senhaMestraStatus = res.data.senha_mestra_status
        this.senhaMestraUltimoLogin = res.data.senha_mestra_ultimo_login

      })
      .catch((err) => this.msg("ERRO AO BUSCAR DADOS", err.response.data, "danger"));
    
    },

    trataBotaoSenhaMestra() {
      if (this.statusSenhaMestra) {
        this.labelBotaoSenhaMestra = 'Desativar senha mestra'
        this.corBotaoSenhaMestra = 'warning' 
      }
      else {
        this.labelBotaoSenhaMestra = 'Ativar senha mestra'
        this.corBotaoSenhaMestra = 'success' 
      }
    },

    alterarStatusSenhaMestra() {

      this.statusSenhaMestra = !this.statusSenhaMestra
      this.trataBotaoSenhaMestra()
      const labelMensagem = this.statusSenhaMestra ? 'ATIVADA' : 'DESATIVADA'
      
      api.put("webadmin/admin/config-senha-mestra", { mudarStatusSenha: this.statusSenhaMestra })
      .then(() => {
        this.msg("SENHA MESTRA", `${labelMensagem} COM SUCESSO.`, "success");
        this.getConfig()
      })
      .catch((err) => this.msg("ERRO AO ATUALIZAR A SENHA MESTRA", err.response.data, "danger"));

    },

    saveTimeAlterarEscala() {
      
      api.put("webadmin/admin/config", { tempo_limite_editar_escala: this.timeEscala })
      .then(() => {
        this.msg("CONFIGURAÇÕES", "ATUALIZADO COM SUCESSO.", "success");
      })
      .catch((err) => this.msg("ERRO AO ATUALIZAR CONFIGURAÇÕES", err.response.data, "danger"));

    },


  },

  


};

</script>


<style scoped>
#form-usuario {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  height: 230px;
  margin-top: 40px;
  width: 100%;
}

.card-dentro{
  margin-top: 40px;
}

.botao {
  margin-top: 30px;
  width: 100%;
}

.botao-cancelar {
  margin-top: 30px;
  margin-left: 15px;
}

.tamanho-padrao {
  width: 300px;
}

</style>