<template>
  <div id="dashboard">
    
    <div>
      <b-card
        style="max-width: 10rem;"
        class="card-documentacao"
        @click="toDocsPendentes"
      >

        <b-card-text class="docs-pendentes centralizado">{{numeroDeCooperados}}</b-card-text>
        
        <b-card-text class="centralizado">Profissionais com documentação pendente</b-card-text>

      </b-card>
    </div>

  </div>
</template>

<script>

import api from "../services/api";

  export default {
    name: "dashboard",

    data() {
      return {

        numeroDeCooperados: ''

      };
    },

    created() {

      this.getCooperadosDocsPendentes()

    },

    methods: {

      msg(titulo, texto, cor) {
        this.toastCount++;
        this.$bvToast.toast(texto, {
          title: titulo,
          variant: cor,
          solid: true,
          autoHideDelay: 5000,
          appendToast: true
        });
      },

      toDocsPendentes(){
        if (this.numeroDeCooperados > 0) this.$router.push('/coopDocsPendentes')
        else this.msg('Atenção', 'Não há cooperados com documentções pendentes no momento.', 'warning')
      },

      getCooperadosDocsPendentes() {

        api.get(`webadmin/dashboard/coopDocsPendentes`)
        .then(res => {

            // console.log(res.data)
            this.numeroDeCooperados = res.data.length
        })
        .catch(err => this.msg(err.response.data, 'vermelho', 5000))
      },

    },

    components: {
      
    }

};
</script>





<style scoped>
  .docs-pendentes {
    font-size: 40px;
  }

  .centralizado {
    text-align: center;
    cursor: pointer;
  }

  .card-documentacao {
    margin: 40px;
  }
</style>


