

<template> 
    <div>
        <center> 
            <b-button  variant="btn btn-outline-success btn-sm" @click="createFile()" > Download</b-button>
        </center>
    </div> 
</template>
 
<script>

  import api from "@/services/api";     
  import dayjs from "dayjs"

  export default { 
    name: 'ModalArquivoRemessaSalvar', 
    props: ['fats'],
    data() {
      return {  

        empresaDadosNota: {},
        faturamentoList: [] 

      }

    },

    created() {    

      this.getFaturamentos(this.fats)  
    }, 

    methods: {  
        
        msg(titulo, texto, cor) {
            this.toastCount++
            this.$bvToast.toast(texto, {
            title: titulo,
            variant: cor,
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
            })
        },

        getFaturamentos(faturamentos){

            const ids =  faturamentos.map(item => item.id) 

            api.get('webadmin/faturamentos/nota-fiscal/' + ids.toString())
            .then(res => {  
                this.empresaDadosNota = res.data   
                this.faturamentoList = res.data.faturamentos   
            })
            .catch(err => this.msg('Erro ao carregar Faturamento', err , 'danger'))

        },

        createFile() {
  
            var filename = `nfs_${dayjs().format('YYYY-MM-DD__HH-mm-ss')}.txt`;
            var texto = '';

            var linhas = 0;
            var total = 0;
            // var totalDescontos = 0;

            // Cabeçalho
            //---------------------------------------------
            texto += this.preparaValor('N', '1', 1);
            texto += this.preparaValor('N', '001', 3);
            texto += this.preparaValor('N', this.empresaDadosNota.emp_insc_municipal, 8);
            texto += this.preparaValor('S', dayjs().format('YYYYMMDD'), 8);
            texto += this.preparaValor('S', dayjs().format('YYYYMMDD'), 8);
            texto += "\n"
            //---------------------------------------------

            
            this.faturamentoList.forEach(item => {             


              texto += this.preparaValor('N', '2', 1);
              texto += this.preparaValor('S', 'RPS', 5);
              texto += this.preparaValor('S', '', 5);
              texto += this.preparaValor('N', item.id, 12);
              texto += this.preparaValor('S', dayjs().format('YYYYMMDD'), 8);
              texto += this.preparaValor('S', item.situacaoTributaria, 1); 
              texto += this.preparaValor('N', this.formatValor(item.valor), 15);
              texto += this.preparaValor('N', '0', 15);
              texto += this.preparaValor('N', this.empresaDadosNota.emp_cod_serv_rps, 5);
              texto += this.preparaValor('N', this.PreparaNumTxt(this.empresaDadosNota.rubricaISS), 4);
              texto += this.preparaValor('N', this.empresaDadosNota.rubricaISS > 0 ? '1' : '2', 1);
              texto += this.preparaValor('N', '2', 1);
              texto += this.preparaValor('N', item.cnpj, 14);
              texto += this.preparaValor('S', '', 8);
              texto += this.preparaValor('S', '', 12);
              texto += this.preparaValor('S', item.razao_social, 75);
              texto += this.preparaValor('S', item.enredereco, 3);
              texto += this.preparaValor('S', item.enredereco, 50);
              texto += this.preparaValor('S', item.numero, 10);
              texto += this.preparaValor('S', item.razao_social, 30);
              texto += this.preparaValor('S', item.bairro, 30);
              texto += this.preparaValor('S', item.cidade, 50);
              texto += this.preparaValor('S', item.uf, 2);
              texto += this.preparaValor('N', item.cep, 8);
              texto += this.preparaValor('S', item.email, 75);
              texto += this.preparaValor('S', item.comentarios, 1000);
              texto += "\n";

              linhas++;
              total += Number(item.valor);
              //totalDescontos += Number.parseInt('1');
        
            })
            
            // Rodapé
            //---------------------------------------------
            texto += this.preparaValor('N', '9', 1);
            texto += this.preparaValor('N', linhas.toString(), 7);
            texto += this.preparaValor('N', this.PreparaNumTxt(total), 15);
            texto += this.preparaValor('N', '0', 15);
            //---------------------------------------------



            this.download(filename, texto)
            this.updateStatusFaturamentos()
        },
        
        preparaValor(tipo, valor, tamanho) {
          var resultado;
          // Verifica qual o tipo de parametro
          // S = string
          // N = numerico

          if (valor === null) valor = ''

          valor = this.retiraCaracteresExpeciais(valor);

          if(tipo == "S"){
            resultado = this.formatString(valor, tamanho);
          } 
          else if (tipo == "N"){
            resultado = this.formatNumber(valor, tamanho);
          }

          return  resultado
        },

        retiraCaracteresExpeciais(valor){

          // console.log('t',t)
          if (!valor) return '' 
          
          valor = valor.toString().replace(/(?:\r\n|\r|\n)/g, '|') 
          valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove acentos 
          valor = valor.replace(/[º]/g, "");
          valor = valor.replace(/[ª]/g, ""); 
          valor = valor.replace(/[-]/g, ""); 
          valor = valor.replace(/[.]/g, ""); 
          valor = valor.replace(/[/]/g, ""); 

          return valor;
        },

        formatString(valor, tamanho){
          var resultado ;
          
          valor = valor.toString().substr(0, tamanho);

          resultado = valor.padEnd(tamanho, " ");
          return resultado
        },

        formatNumber(valor, tamanho){
          var resultado;

          valor = valor.toString().substr(0, tamanho);

          resultado = valor.padStart(tamanho, "0");
          return resultado
        },

        formatValor(valor, tamanho){
          var resultado;

          const valorTemp = parseFloat(valor).toFixed(2).toString().split('.').join('')
          valor = valorTemp.substr(0, tamanho);

          resultado = valor.padStart(tamanho, "0");
          return resultado
        },
        
        PreparaNumTxt(num){ 

          var numero  = num;
          numero = Math.round( numero * 100);
          
          return (numero).toString();
        },

        download(filename, text) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none'; 

            document.body.appendChild(element); 
            element.click(); 
            document.body.removeChild(element);
        }, 

        updateStatusFaturamentos() {

            api.put('webadmin/faturamentos/nota-fiscal/update-status', this.faturamentoList)
            .then(() => {  
                this.$bvModal.hide('modal-txtsalvar')
            })
            .catch(() => this.msg('ATENÇÃO', 'Erro ao atualizar status do(s) faturamento(s)', 'danger'))
        },

        
    } 
  };
</script>
 
     
<style scoped> 
 
</style>