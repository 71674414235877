<template>

  <div class="list-group-item">
    <div id="editarGestor">
      <h2 class="titulo2"> Editar Gestor</h2>
      <b-button
          class="btnRight"
          variant="btn btn-outline-success btn-sm"
          link to="../solicitacoes"
      > Voltar
      </b-button>

      <hr>

      <div class="form-row">
        <div class="col-lg-12">
          <b-jumbotron>
            <template #header>Informações da Solicitação</template>

            <hr class="my-4">

            <div class="info-solicitacao col-lg-6">
              <p>
                CLIENTE: <strong>{{topo.cliente}}</strong>
              </p>
              <p>
                SOLICITANTE: <strong>{{topo.solicitante}}</strong>
              </p>
              <p>
                DATA: <strong>{{topo.dataEvento}}</strong>
              </p>
              <p>
                SETOR: <strong>{{topo.nomeEvento}}</strong>
              </p>
              <p>
                COMENTÁRIO: <strong>{{topo.obs}}</strong>
              </p>
            </div>

            <div class="info-solicitacao2 col-lg-6">
              <p>
                FUNÇÃO: <strong>{{topo.funcao}}</strong>
              </p>
              <p>
                ENTRADA: <strong>{{topo.entrada}}</strong>
              </p>
              <p>
                SAIDA: <strong>{{topo.saida}}</strong>
              </p>
              <p>
                STATUS: <strong>{{topo.status}}</strong>
              </p>
              <p>
                GESTOR ATUAL: <strong>{{topo.gestor}}</strong>
              </p>
            </div>

          </b-jumbotron>
        </div>

        <div class="linhaVermelha"></div>


        <div class="col-lg-12">
          <b-jumbotron>
            <template #header>Filtrar Gestores</template>

            <hr class="my-4">

            <div class="info-solicitacao col-lg-12">
              <div class="form-row">

                <div class="form-group col-lg-6">
                  <label><span class="text-danger">*</span> Nome</label>
                  <input class="form-controler"
                         v-model="busca"
                  >
                </div>

                <!-- <div class="form-group col-lg-3">
                  <label><span class="text-danger">*</span> Cidade</label>
                  <input class="form-controler"
                         v-model="buscaCidade"
                  >
                </div>

                <div class="form-group col-lg-3">
                  <label><span class="text-danger">*</span> Região</label>
                  <input class="form-controler"
                         v-model="buscaRegiao"
                  >
                </div> -->

              </div>
            </div>

          </b-jumbotron>
        </div>
      </div>


      <b-jumbotron bg-variant="light">

        <table class="table-responsive  table-striped table">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Nome</th>
            <th scope="col">Cidade</th>
            <th scope="col">Região</th>
            <th scope="col">Visualizar Gestor</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="item in resultadoBusca" :key="item.id" >

            <td><input
                type="radio"
                :value="item.id"
                v-model="selectedGestor"
            ></td>
            <td>{{ item.nome }}</td>
            <td>{{ item.cidade }}</td>
            <td>{{ item.regiao }}</td>
            <td>
              <b-button
                  variant="outline-primary btn-sm"
                  class="mr-2"
                  @click="visualizarGestor(item)"
              >visualizar
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-jumbotron>

      <b-button
          class=""
          type="submit"
          variant="success m-3"
          @click="trocarGestor"
      >Confirmar troca de gestor
      </b-button>

    </div>

  </div>
</template>


<script>
// import firebase from '../services/firebaseConnection';
import api from "../../services/api";
// import axios from "axios";

export default {
  name: "EditarGestor",

  data() {
    return {
      selecione_tudo: false,
      selectedGestor: null,
      busca: '',
      buscaCidade: '',
      buscaRegiao: '',

      topo: {},

      selected: "selecione",

      items: [],
      show: true
    };
  },

  computed: {
    resultadoBusca: function () {
      if (this.busca.trim() == '') {
        return this.items;
      } else {
        return this.items.filter((item) => {

          // return item.nome.toLowerCase().match(this.busca);

          const minuscula = this.busca.toLowerCase();
          
          const nomeESobrenome = minuscula.split(' ')

          for (let i = 0; i < nomeESobrenome.length; i++) {
            nomeESobrenome[i] = nomeESobrenome[i][0].toUpperCase() + nomeESobrenome[i].substr(1)
          }

          const buscaAdaptada = nomeESobrenome.join(' ')

          return item.nome.match(buscaAdaptada);
        })
      }
    }
  },

  created() {

    const solicitacao = localStorage.getItem('solicitacao')
    this.topo = JSON.parse(solicitacao)

    api.get('webadmin/solicitacoes/gestores')
        .then(res => {

          // console.log(res.data);
          this.items = res.data;
        })
        .catch(err => console.log(err))

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    select() {
      this.selected5 = [];
      if (!this.selecione_tudo) {
        for (let i in this.items) {
          this.selected5.push(this.items[i].id);
        }
      }
    },

    visualizarGestor(gestor) {
      console.log('gestor', gestor)
      const idUsuario = gestor.id

      api
      .get(`webadmin/solicitacoes/cooperado/${idUsuario}`)
      .then(res => {
        // console.log('cooperado', res.data) 
        
        const cooperado = JSON.stringify(res.data);
        localStorage.setItem("cooperado", cooperado);
        this.$router.push("/coopView"); 

        // let route = this.$router.resolve({path: '/coopView'});
        // window.open(route.href, '_blank');
      })
      .catch(() => {
        this.msg('ERRO...', 'Falha ao buscar cooperado', 'danger')
      });

    },

    trocarGestor() {
      // console.log(this.selectedGestor)

      if (this.selectedGestor < 1) {
        this.msg('ATENÇÃO', 'Selecione o novo gestor.', 'warning')
        return
      }

      const dados = {
        idEscala: this.topo.id,
        idGestorAtual: this.topo.idGestor,
        idGestorNovo: this.selectedGestor
      }

      api
      .put("webadmin/solicitacoes/gestores", dados)
      .then(() => {

        this.msg('GESTOR SUBSTITUÍDO', 'O gestores serão avisados.', 'success')
        this.$router.push('/solicitacoes')
      })
      .catch(err => {
        console.log('fail')
        this.msg('ERRO AO CONFIRMAR', err.response.data, 'danger')
      });

    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();

      this.form.id_gestor = localStorage.getItem("id_gestor");

      api
          .post("webadmin/editar_gestor", this.form)
          .then(res => {
            this.msg("EMISSAO", "CONFIRMADO COM SUCESSO.", "success");
            localStorage.setItem("id_gestor", res.data.id_gestor);
            this.onReset();
            this.getEditarGestor();
          })
          .catch(err =>
              this.msg("ERRO AO CONFIRMAR", err.response.data, "danger")
          );
    }
  }
};
</script>


<style scoped>
#editarGestor {
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.table-responsive {
  display: table;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 576px) {
  .table-responsive {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
}

.table-striped tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

input[type=checkbox], input[type=radio] {
  cursor: pointer;
}


.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
}

@media screen and (max-width: 480px) {
  .bloco1 {
    width: 100%;
    flex-direction: column;
  }
}


.titulo2 {
  display: flex;
  margin-right: 0;
}

.linhaVermelha {
  width: 100%;
  border-bottom: 1px solid #f21d1d !important;
  margin-bottom: 25px;
  margin-top: 25px;
}


.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-3
{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

}

.list-group-item {
  position: inherit;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #CCC;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 12px;
}

@media screen and (max-width: 480px) {
  .list-group-item {
    display: block;
    padding: 6px 6px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.data-v-email {
  width: 4.7rem;
}

.display-3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
}

.info-solicitacao {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.info-solicitacao2 {
  font-size: 14px;
  font-weight: 500;
  float: right;
  margin-top: -11rem;
}

@media screen and (max-width: 480px) {
  .info-solicitacao2 {
    margin-top: 0;
    float: none;
  }
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 1rem;
  }
}

.form-controler {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-controler:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: hsla(0, 0%, 100%, 0.014);
  background-clip: padding-box;
  border: 1px solid #ced4da00;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>