<template>
  <div id="funcoes">

    <h2 class="titulo2">Nova Função</h2>

    <b-form
        @submit="onSubmit"
        @reset="onReset"
        v-if="show"
    >
      <div class="form-row">

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Local</label>
          <b-form-select
              id="input-1"
              v-model="form.local"
              :options="locais"
              @change="getSetores"
              required
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Setor</label>
          <b-form-select
              id="input-2"
              v-model="form.setor"
              :options="setores"
              required
          ></b-form-select>
        </div>

        <div class="form-group col-lg-2">
          <label><span class="text-danger">*</span> Atividade</label>
          <b-form-select
              id="input-3"
              v-model="form.atividade"
              :options="atividades"
              required
          ></b-form-select>
        </div>

        <div class="form-group col-lg-2">
          <label><span class="text-danger">*</span> Função</label>
          <b-form-input
              id="input-4"
              v-model="form.funcao"

          ></b-form-input>
        </div>

        <div class="form-group col-lg-2">
          <label><span class="text-danger">*</span> Uniforme</label>
          <b-form-input
              id="input-4"
              v-model="form.uniforme"

          ></b-form-input>
        </div>

        <!-- <b-form-group class="col-lg-2"><label><span class="text-danger">*</span> Uniforme:</label>
           <b-form-select
               id="input-7"
               v-model="form.uniforme"
               :options="uniformes"
               required
           ></b-form-select>
         </b-form-group> -->

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
        <div class="col-lg-6">
          <b-form-group label="Medição">
            <b-form-radio-group
                v-model="form.medicao"
                :options="options"
                name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <div class="form-group col-lg-2">
          <b-form-group id="input-group-8" label="Carga normal de horas:" label-for="input-8">
            <b-form-input type="number"
                          id="input-8"
                          v-model="form.cargaHoras"
                          required
            ></b-form-input>
          </b-form-group>
        </div>


        <div class="form-group col-lg-2">
          <b-form-group id="input-group-9" label="Máximo adicionais:" label-for="input-9">
            <b-form-input type="number"
                          id="input-9"
                          v-model="form.maximoAdic"
                          required
            ></b-form-input>
          </b-form-group>

        </div>

        <div class="linhaVermelha"></div>
        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXX--Cooperado--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

        <div class="form-row cooperado">
          <h3 class="">Cooperado:</h3>


          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> Hora Normal</label>
            <b-form-input
                id="input-4"
                v-model="form.horaNorProf"
                placeholder="0,000"
                @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> Extra</label>
            <b-form-input
                id="input-4"
                v-model="form.extraProf"
                placeholder="0,000"
                @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-2">
            <label><span class="text-danger">*</span> Noturno</label>
            <b-form-input
                id="input-4"
                v-model="form.noturnoProf"
                placeholder="0,000"
                @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-2">
            <label><span class="text-danger">*</span> Extra Noturno</label>
            <b-form-input
                id="input-4"
                v-model="form.extraNotProf"
                placeholder="0,000"
                @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

        </div>

        <div class="linhaVermelha"></div>
        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXX--Cliente--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

        <h3 class="titulo2 mx-4">Cliente:</h3>


        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Hora Normal</label>
          <b-form-input
              id="input-4"
              v-model="form.horaNorCli"
              placeholder="0,000"
              @keyup="formatMoeda($event)"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Extra</label>
          <b-form-input
              id="input-4"
              v-model="form.extraCli"
              placeholder="0,000"
              @keyup="formatMoeda($event)"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-2">
          <label><span class="text-danger">*</span> Noturno</label>
          <b-form-input
              id="input-4"
              v-model="form.noturnoCli"
              placeholder="0,000"
              @keyup="formatMoeda($event)"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-2">
          <label><span class="text-danger">*</span> Extra Noturno</label>
          <b-form-input
              id="input-4"
              v-model="form.extraNotCli"
              placeholder="0,000"
              @keyup="formatMoeda($event)"
          ></b-form-input>
        </div>


        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      </div>

      <!-- <b-button
          class="col-md-1 mb-3"
          type="submit"
          :variant="corBtn">{{statusBtn}}
      </b-button> -->

      <div class="form-group col-lg-3" v-if="insertDB">
        <b-button class="col-md-5 m-1" type="submit" variant="success">Salvar</b-button>
      </div>

      <div class="form-group col-lg-3" v-else>
        <b-button class="col-md-5 m-1" type="submit" variant="warning">Atualizar</b-button>
        <b-button class="col-md-5 m-1" type="submit" variant="danger" @click="insertDB=true; getFuncao(); onReset()">Cancelar</b-button>
      </div>

    </b-form>

<!--    <b-card-->
<!--        class="mt-3"-->
<!--        header="Resultado do Form"-->
<!--    >-->
<!--      <pre class="m-0">{{ form }}</pre>-->
<!--    </b-card>-->


    <div>
        <b-modal ref="my-modal" hide-footer title="">
            <div class="d-block text-center">
                <h3>Você confirma a exclusão?</h3>
            </div>
            <b-button class="mt-3" variant="outline-success" block @click="apagar">Sim</b-button>
            <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
        </b-modal>
    </div>



    <div class="linhaVermelha"></div>

    <div class="marginTopo">
      <b-table
          striped
          responsive
          hover
          :fields="fields"
          :items="items"
      >
        <template v-slot:cell(actions)="data">
          <b-button variant="outline-warning btn-sm" class="mr-2" @click="editar(data.item)">Editar</b-button>
          <b-button variant="outline-danger btn-sm" class="mr-2" @click="showModal(data.item)">Apagar</b-button>
        </template>
      </b-table>
    </div>

  </div>

</template>


<script>
import api from "../../services/api";

export default {
  name: "Funcoes",
  data() {
    return {
      
      insertDB: true,

      items: [],

      fields: [
        {key: "nomeLocal", label: "Local"},
        {key: "nomeSetor", label: "Setor"},
        {key: "nomeAtividade", label: "Atividade"},
        {key: "funcao", label: "Função"},
        {key: "horaNorProf", label: "Normal"},
        {key: "extraProf", label: "Extra"},
        {key: "noturnoProf", label: "Noturno"},
        {key: "extraNotProf", label: "ExtraNot"},

        {key: "horaNorCli", label: "NormalCli"},
        {key: "extraCli", label: "ExtraCli"},
        {key: "noturnoCli", label: "NoturnoCli"},
        {key: "extraNotCli", label: "ExtraNotCli"},
        {key: "medicao", label: "Medida", formatter: value => {
            return value == 'F' ? 'Fechado' : 'Hora'
        }},
        {key: "cargaHoras", label: "Carga"},
        {key: "maximoAdic", label: "AdicMax"},
        {key: "actions", label: "Ações"},
      ],

      form: {
        idCliente: "",
        local: null,
        setor: null,
        atividade: null,
        funcao: null,
        uniforme: null,
        medicao: null,
        cargaHoras: 8,
        maximoAdic: 3,
        horaNorProf: null,
        extraProf: null,
        noturnoProf: null,
        extraNotProf: null,
        horaNorCli: null,
        extraCli: null,
        noturnoCli: null,
        extraNotCli: null,
      },

      locais: [
        {text: "Selecione", value: null},
        "Google",
        "Carrefur",
        "Marisa",
        "Olimpía"
      ],

      setores: [],

      atividades: [],

      funcao: [
        {text: "Selecione", value: null},
        "Garçon Lider",
        "Abridor Lider",
        "Amassa latinha Lider",
        "Limpa mesa Lider"
      ],

      uniforme: [
        {text: "Selecione", value: null},
        "Preto Padrão",
        "Laranja Bolinha",
        "Gravata Piriquito",
        "Calsa Pantalona"
      ],

      selected: "medicao",
      options: [
        {text: "Hora", value: "H"},
        {text: "Fechado", value: "F"}
      ],
      show: true
    };
  },

  // created TRAZER AS INFORMAÇÃO PARA O SELECT FUNÇÕES
  created() {
    this.getFuncao()

    const idCliente = localStorage.getItem('idCliente');
    // console.log('idcliente', idCliente)
    api.get(`webadmin/clientes/unidades/${idCliente}`)
        .then(res => {

          //MONTAR A LISTA DE SETORES DO SELECT
          const novaLista = res.data.map(items => {
            const unidade = {
              value: items.id,
              text: items.nome,
            }
            return unidade;
          })

          console.log(novaLista);
          this.locais = novaLista;
        })
        .catch(err => this.msg('Erro: Ao buscar Setores.', err, 'danger'));

    //BUSCAR ATIVIDADE
    api.get('webadmin/clientes/atividades')
        .then(res => {
          console.log('Atividades', res.data);
          this.atividades = res.data
        })
        .catch(err => this.msg('Erro: Ao buscar Atividades33.', err, 'danger'));
  },


  methods: {

    formatMoeda(event) {
      
      // console.log(event.target.value)
      let input = event.target

      var valor = input.value.replace(/,/, '')

      if (valor.length > 3)
          input.value = valor.substring(0, valor.length - 3) +','+ valor.substring(valor.length - 3, valor.length)
      else   
      if (valor.length > 0 && valor.length < 4)
          input.value = valor
      else   
          input.value = ''

  },

    editar(item) {
      // console.log(item)
      this.form = item
      this.insertDB = false
      this.getSetores()
    },

    apagar() {
        this.$refs['my-modal'].hide()

        api.delete(`webadmin/clientes/funcao/${this.form.id}`)
        .then(() => {

            this.msg('FUNÇÃO', 'REMOVIDO COM SUCESSO.', 'success')
            this.onReset()
            this.getFuncao()
        })
        .catch(err => this.msg('ERRO AO REMOVER FUNÇÃO.', err.response.data, 'danger'))
    },

    showModal(data) {
        this.form.id = data.id
        // console.log(data.id)
        this.$refs['my-modal'].show()
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    getSetores() {
      api.get(`webadmin/clientes/usuarios/setores/${this.form.local}`)
          .then(res => {
            // console.log('Setores',res.data);
            this.setores = res.data;
          })
          .catch(err => this.msg('Erro: Ao buscar Setores.', err, 'danger'))
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.form.id) {

        api.put('webadmin/clientes/funcao', this.form)
            .then(() => {
              this.msg('FUNÇÃO', 'ATUALIZADA COM SUCESSO.', 'success')
              this.getFuncao()
              this.onReset()
              delete this.form.id
              this.insertDB = true
            })
            .catch(err => this.msg('ERRO AO ATUALIZAR FUNÇÃO', err.response.data, 'danger'))

      } else {

        this.form.idCliente = localStorage.getItem('idCliente');

        api.post('webadmin/clientes/funcao', this.form)
            .then(() => {
              this.msg('FUNÇÃO', 'CADASTRADA COM SUCESSO.', 'success')

              // QUANDO SALVAR TRAGO DE NOVO A LISTA
              this.getFuncao()
              this.onReset()


            })
            .catch(err => this.msg('ERRO AO CADASTRAR FUNÇÃO', err.response.data, 'danger'))
      }
    },


    getFuncao() {
      const idCliente = localStorage.getItem('idCliente');
      api.get(`webadmin/clientes/funcoes/${idCliente}`)
          .then(res => {
            console.log('Funções', res.data);
            this.items = res.data
          })
          .catch(err => this.msg('Erro: Ao buscar Funcões.', err, 'danger'));
    },

    onReset() {

          this.form.idCliente = null,
          this.form.local = null,
          this.form.setor = null,
          this.form.atividade = null,
          this.form.funcao = null,
          this.form.uniforme = null,
          this.form.medicao = null,
          this.form.cargaHoras = null,
          this.form.maximoAdic = null,
          this.form.horaNorProf = null,
          this.form.extraProf = null,
          this.form.noturnoProf = null,
          this.form.extraNotProf = null,
          this.form.horaNorCli = null,
          this.form.extraCli = null,
          this.form.noturnoCli = null,
          this.form.extraNotCli = null
    }
  }
};
</script>

<style scoped>
.cooperado {
  margin-bottom: 25px;
}

.table-titulo {
  width: 100%;
  margin-bottom: 1rem;
  color: #db3737;
  font-size: 14px;
  font-weight: bold;
}

.linhaVermelha {
  width: 100%;
  border-bottom: 1px solid #f21d1d !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

label {
  float: left;
  display: inline-block;
  margin-bottom: .5rem;
}


.titulo2 {
  display: flex;
  margin-right: 0;
}

.linhaVermelha {
  border-top-style: groove;
}

.marginTopo {
  margin-top: 25px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;


}

.b-form-btn-label-control.form-control {
  background-image: none;
  padding: 0;
  width: 62%;
  margin: raiht;
  margin-left: 20%;
  margin-bottom: 8%;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
</style>