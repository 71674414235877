<template>
  <div id="app">
    <template v-if="displaySystem">
      <Header v-if="statusMenu"/>
      <sidebar-component />
    </template>
    
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/usuario">Usuario</router-link> |
      <router-link to="/cooperado">Cooperado</router-link>
    </div> -->
    <router-view/>
  </div>
</template>


<script>

import Header from './components/Header';
import SidebarComponent from './components/SidebarComponent';
import localStorageHelper from './helpers/local-storage-helper';
import api from './services/api';
import authService from './services/authService';

export default {
  components: {
    Header,
    SidebarComponent
  },
  data() {
    return {
      displaySystem: false
    }
  },
  async beforeCreate() {
    const configResp = await fetch("config.json");
    const conf = await configResp.json();
    api.defaults.baseURL = conf.api_url;
    this.displaySystem = true;
  },
  created() {
    this.initStoreData();
    this.validateToken();
    // Deixa acessar pagina sem autenticar se for o caminho abaixo, se não ele direciona para o login.
    try {

        // console.log("iniciouuu",this.$router);
        // console.log("path", path);

        const path = this.$router.history._startLocation.split('?')[0]

        if (path == '/nova-senha') {
            this.$router.push(this.$router.history._startLocation)
        } 
        // else {
        //     this.$router.push("/login") 
        // }

    
    } catch (error) {
        this.$router.push("/login") 
    }
    
  },

  computed: {
    statusMenu() {
      return this.$store.state.auth.isMenuVisible
    }
  },
  methods: {
    initStoreData() {
        const userData = localStorageHelper.getCurrentUser();
        if (userData) {
            this.$store.commit("auth/setUser", userData);
        }
    },
    async validateToken() {
    //   console.log("valitation");
      this.validatingToken = true;
      if (!this.currentUser) {
        this.validatingToken = false;
        this.$store.commit("auth/setUser", null);
        this.$router.push("login");
        return;
      }
      
      const res = await authService.validateToken(new URLSearchParams(), {
        token: this.currentUser.token,
      });
      if (res.data) {
        // this.$store.commit("auth/setUser", userData);
        this.$router.push({ name: "home" });
      }
      this.validatingToken = false;
    },
  }
}
</script>









<style lang="scss">
#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
