<template>


   <div class="content p-1">
   <div class="jumbovindo">
  <div>
  <!-- <b-card no-body class="overflow-hidden" style="max-width: 540px;">
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img src="https://picsum.photos/400/400/?image=20" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body title="Horizontal Card">
          <b-card-text>
            This is a wider card with supporting text as a natural lead-in to additional content.
            This content is a little bit longer.
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card> -->
</div>
</div>
   </div>

</template>

<script>
export default {
  name: "Home",
  components: {

  }
}

</script>

<style scped>
h2 {
  color: brown;
}

.p-1 {
    padding: 0.25rem !important;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
}
</style>


