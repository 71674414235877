

<template> 
  <div> 
    <b-row> 
      <b-col sm="7" style="padding-right:7px;  ">
        <b-card bg-variant="light" style="min-height:100%%;">

          <b-row> 
            <b-col sm="6">
              <b-form-group label="Nome" label-for="input-nome" >
                <b-form-input  id="input-nome" v-model="cliente.nomeComercial" :readonly="isReadOnly()"></b-form-input>
              </b-form-group> 
            </b-col>

            <b-col sm="3">
              <b-form-group label="CNPJ" label-for="input-cnpj" >
                <b-form-input id="input-cnpj" v-model="cliente.cnpj" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col>

            <b-col sm="3">
              <b-form-group label="Ins. Municipal" label-for="input-inscricaoMunicipal" >
                <b-form-input id="input-inscricaoMunicipal" v-model="cliente.inscricaoMunicipal" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col> 
          </b-row>  
                
          <b-row> 
            <b-col >
              <b-form-group label="Endereco" label-for="input-endereco" >
                <b-form-input  id="input-endereco" v-model="cliente.endereco" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col> 
          </b-row>  

          <b-row> 
            <b-col sm="4">
              <b-form-group label="Municipio" label-for="input-municipio" >
                <b-form-input  id="input-municipio" v-model="cliente.cidade" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col> 
            <b-col sm="2">
              <b-form-group label="UF" label-for="input-uf" >
                <b-form-input  id="input-uf" v-model="cliente.uf" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col> 
            <b-col sm="6">
              <b-form-group label="E-mail" label-for="input-email" >
                <b-form-input  id="input-email" v-model="cliente.email" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col> 
          </b-row>  

          <b-row> 
            <b-col sm="4">
              <b-form-group label="Codigo Servico" label-for="input-codigoServico" >
                <b-form-input  id="input-codigoServico" v-model="cliente.codigoServico" :readonly="true"></b-form-input>
              </b-form-group> 
            </b-col> 

            <b-col sm="4">
              <b-form-group label="Link da NF" label-for="input-linkNF" >
                <b-form-input  id="input-linkNF"  @change="atualizaFaturamento(true)" v-model="faturamento.link_nf" :readonly="!permiteUpdateFaturamento"></b-form-input>
              </b-form-group> 
            </b-col> 

            <b-col sm="4">
              <b-form-group label="Numero NF" label-for="input-numero" >
                <b-form-input  id="input-numero" :readonly="!permiteUpdateFaturamento" @change="atualizaFaturamento(true)" v-model="faturamento.nota_fiscal"></b-form-input>
              </b-form-group> 
            </b-col> 
          </b-row>

          <b-row>  
            
            <b-col sm="4">
              <b-form-group label="Data Emissao" label-for="input-dataEmissao" >
                <b-form-datepicker id="input-dataEmissao" 
                  :readonly="!permiteUpdateFaturamento"
                  v-model="faturamento.data_emissao" 
                  placeholder="__/__/____"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @context="atualizaFaturamento(true)">
                </b-form-datepicker> 
              </b-form-group> 
            </b-col> 
            <b-col sm="4">
              <b-form-group label="Data Vencimento" label-for="input-dataVencimento" >
                <b-form-datepicker id="input-dataVencimento" 
                  :readonly="!permiteUpdateFaturamento"
                  v-model="faturamento.data_vencimento" 
                  placeholder="__/__/____"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @context="atualizaFaturamento(true)">
                </b-form-datepicker>  
              </b-form-group> 
            </b-col> 

            <b-col sm="4">
              <b-form-group label="Meio Pagamento" label-for="input-dataVencimento" >
                <b-form-select  
                  :disabled="!permiteUpdateFaturamento"
                  v-model="faturamento.meio_pagamento" 
                  :options="opcoesMeioPagamento" 
                  @change='atualizaFaturamento(true)'
                ></b-form-select>
              </b-form-group> 
            </b-col> 

          </b-row>  

          <b-row> 
            <b-col sm="12">
              <b-form-group label="Comentarios" label-for="input-comentarios" >
                <b-form-textarea
                  id="input-comentarios"
                  v-model="faturamento.comentarios" 
                  rows="10"
                  max-rows="15"
                  class="font-comentarios"
                  @change='atualizaFaturamento(false)'
                ></b-form-textarea>
              </b-form-group> 
            </b-col> 
          </b-row>   

        </b-card>
      </b-col>


      <!-- ------------------------------------------- -->

      <b-col sm="5" style="padding-left:7px;">
        <b-card bg-variant="light" style="min-height: 100%;">
        
          <!-- <faturamento-edit-ajustes :faturamento="this.faturamento" v-on:atualiza="atualizaCalculo++"></faturamento-edit-ajustes> -->
        



        <div>

          <div style="min-height: 250px; max-height: 300px;"> 

            <!-- <b-card class="mt-3" header="Resultado do Form">
                  <pre class="m-0">faturamentosRubricas: {{ faturamentosRubricas }}</pre>
              </b-card> -->
            
            <b-table small striped  sticky-header  borderless hover :items="tabelaAjustes" :fields="tabelaAjustesColunas">
                  
              <!-- Coluna Tipo Rubrica --> 
              <!-- <template #cell(tipo)="row" > 
                <template v-if="row.item.tipo ==  1"> Entrada  </template>
                <template v-else> Saida  </template>  
              </template> -->

              <template #cell(valor) ="row">  
                <b-form-input type="number" size="sm" @change="atualizaRubricaAjusteValor(row.item)"  v-model="row.item.valor"  />
              </template>

              <!-- Coluna Excluir -->
              <template #cell(excluir)="row">
                <center>
                  <b-button class="btn-sem-borda" type="button" @click="delRubricaAjuste(row.item)" variant="outline-warning btn-sm" title="Excluir Ajuste" > 
                    <font-awesome-icon class="ico-excluir" icon="trash-alt" />
                  </b-button>  
                </center>
              </template> 

            </b-table>
          </div>
          <b-form-select  
            v-model="selRubricaAjuste" 
            :options="opcoesRubricasAjustes" 
            @change='addAjustes($event)'
          ></b-form-select>
          <hr>


          <b-table small striped borderless hover :items="tabelaFaturamento" :fields="tabelaFaturamentoColunas"> 

            <template #cell(porcentagem)="row">

              <div v-if="row.item.denominacao == 'ISS'">
                <b-form-input  type="number" size="sm" style="width:40%;" @change="onChangeAtualizaISS(row.item)" v-model="row.item.porcentagem" />
              </div>
              <div v-else>  

                <div v-if="row.item.porcentagem > 0">
                  <b>{{ row.item.porcentagem }}</b> 
                </div>
              </div> 
            </template> 
            
            <!-- Coluna de valor -->
            <!-- <template #cell(valor)="row">
                {{ row.item.valor }}
            </template>  -->

          </b-table> 

        </div>
        
        </b-card>
      </b-col>

    </b-row>
 
  </div> 
</template>
 
<script>

  import api from "@/services/api";    
  // import FaturamentoEditAjustes from './FaturamentoEditAjustes.vue';

  export default {
  // components: { FaturamentoEditAjustes },
    name: 'FaturamentoEdit', 
    data() {
      return {  

        permiteUpdateFaturamento: false,

        faturamento: {},
        cliente: {}, 
        atualizaCalculo:0,
        ajustes: [],
        rubricas: [],
        clientesRubricas:[],
        readOnlyField:true,
        aFaturar:0,

        opcoesMeioPagamento: ['DEPÓSITO','BOLETO'],


        //-----------------------------------------------





        //DADOS DO FATURAMENTO
        //rubricas: {},
        faturamentosRubricas: {},
        // clientesRubricas:{}, 

        //VARIAVEIS da tela
        tabelaAjustes:[],
        tabelaAjustesColunas: [
          { key: "codigo", label: "Código"},
          { key: "denominacao", label: "Denominação"},
          { key: "tipo", label: "Tipo"},
          { key: "valor", label: "Valor"},
          'Excluir'
        ],
        tableAjustesConfig:  '"min-width":"300px"' ,

        selRubricaAjuste: {},
        opcoesRubricasAjustes: [],

        tabelaFaturamento:[],  
        tabelaFaturamentoColunas: [ 
          { key: "denominacao", label: "Denominacao"},
          { key: "porcentagem", label: "%"},
          { key: "valor", label: "Valor", thClass: 'text-center', tdClass: 'text-right'} 
        ],

        totalAjustes:0, 
        totalAjustesCalculaImposto: 0,
        baseCalculoImpostos: 0,
        ValorTotal: 0

      }
    },

    created() {   
      
      this.faturamento = JSON.parse(localStorage.getItem('faturamentoEdit'))
      this.getFaturamento(this.faturamento.id)


      // Usado para proteger de atualizar o faturamento quando abre o modal.
      const timer = setInterval(() => {
        this.permiteUpdateFaturamento = true
        clearInterval(timer)
      }, 2000) 


      //-------------------------------

      this.getFaturamentoRubricas()
      this.getFaturamentoRubricasAjustes()

    },
   
    methods: {  

      msg(titulo, texto, cor) {
        this.toastCount++
        this.$bvToast.toast(texto, {
          title: titulo,
          variant: cor,
          solid: true,
          autoHideDelay: 5000,
          appendToast: true
        })
      },

      getFaturamento(idFaturamento){
         
         api.get('webadmin/faturamentos/' + idFaturamento)
        .then(res => {
          this.faturamento = res.data
          this.getCliente(this.faturamento.id_cliente); 
        })
        .catch(err => this.msg('Erro ao carregar Faturamento', err.response.data, 'danger'))

      },

      getCliente(idCliente){

        api.get('webadmin/faturamento-cliente/' + idCliente)
        .then(res =>  { 
          this.cliente =  res.data; 
          this.cliente.endereco = this.cliente.logradouro + ", " + this.cliente.numero + ", " + this.cliente.bairro
        })
        .catch(err => this.msg('Erro ao buscar cliente', err.response.data, 'danger'))
      },
      
      atualizaFaturamento(gerarComentarios) {

        if (!this.permiteUpdateFaturamento) return
        if (!this.faturamento.id) return

        if (this.faturamento.nota_fiscal == "") {
          this.faturamento.nota_fiscal = null
          this.faturamento.data_emissao = null
        }

        if (this.faturamento.nota_fiscal != null)
          this.faturamento.status = "NF Emitida"; 
        else
          this.faturamento.status = "gerarNF";  

        api.put('webadmin/faturamentos/' + this.faturamento.id, this.faturamento) 
        .then(() => { 
          if (gerarComentarios)
          this.getFaturamentoComentarios()
        })
        .catch(err => this.msg('Erro ao atualizar faturamento', err.response.data, 'danger'))
      },

      getFaturamentoComentarios() {
         
         api.get('webadmin/faturamento-comentarios/'+this.faturamento.id)
        .then(res => {   
          // console.log('gerouuuuuuu')
          this.faturamento.comentarios = res.data
        })
        .catch(err => this.msg('Erro ao buscar comentários', err.response.data, 'danger'))
      },

       
      isReadOnly(){
        return this.readOnlyField
      },


      //--------------------------------


      getFaturamentoRubricas() {  

        //this.faturamento = JSON.parse(localStorage.getItem('faturamentoEdit'))
         
        api.get('webadmin/faturamento/rubricas/'+this.faturamento.id)
        .then(res =>  {  
          this.tabelaFaturamento = res.data

          if (this.permiteUpdateFaturamento)
          this.getFaturamentoComentarios()
        })
        .catch(err => this.msg('Erro ao carregar Rubricas', err.response.data, 'danger')) 
      }, 

      getFaturamentoRubricasAjustes() {  

        //this.faturamento = JSON.parse(localStorage.getItem('faturamentoEdit'))
         
        api.get('webadmin/faturamento/rubricas-ajustes/'+this.faturamento.id)
        .then(res =>  {  
          this.opcoesRubricasAjustes = res.data.opcoesRubricasAjustes
          this.tabelaAjustes = res.data.faturamentosRubricasAjustes
        })
        .catch(err => this.msg('Erro ao carregar Rubricas', err.response.data, 'danger')) 
      }, 

      onChangeAtualizaISS(item) {  
        
        item.idFaturamento = this.faturamento.id

        api.put('webadmin/faturamento/rubricas/iss', item)
        .then(() => {
          // console.log('Atualizado com sucesso.',res)
          this.getFaturamentoRubricas()
        })
        .catch(err => console.log('Erro ao atualizar Ajuste', err))
        
      },

      addAjustes(rubricaAjuste){
        
        api.post('webadmin/faturamentos-rubricas', rubricaAjuste)
        .then(() => {
          // this.msg('FATURAMETO RUBRICA', 'CADASTRADO COM SUCESSO.', 'success')
          this.getFaturamentoRubricas()
          this.getFaturamentoRubricasAjustes()
        })
        .catch(err => this.msg('ERRO AO CADASTRAR FATURAMENTO RUBRICA', err.response.data, 'danger'))
      }, 

      atualizaRubricaAjusteValor(rubricaAjuste){
        
        api.put('webadmin/faturamento-rubrica-ajuste',  rubricaAjuste)
        .then(() => {
          this.msg('Ajuste', 'Atualizado com sucesso.', 'success');
          this.getFaturamentoRubricas()
          this.getFaturamentoRubricasAjustes()
        })
        .catch(err => this.msg('Erro ao atualizar Ajuste', err.response.data, 'danger'))
        
      },  

      delRubricaAjuste(ajuste){ 
 
        api.delete('webadmin/faturamento-rubrica-ajuste/' + ajuste.id)
        .then(() => { 
          this.msg('Ajuste', 'Removido com sucesso.', 'success'); 
          this.getFaturamentoRubricas()
          this.getFaturamentoRubricasAjustes()
        })
        .catch(err => this.msg('Erro ao deletar Ajuste.', err, 'danger'))
 
      },


    } 

  };

</script>
 
     
<style scoped> 

.font-comentarios {
  font-size: 14px;
}
 
</style>