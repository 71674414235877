<template>
  <div
    id="UserList"
    class="contorno"
  >

    <div class="d-flex">
      <div class="mr-auto p-2">
        <h2 class="display-4 titulo">Gerenciar Usuários</h2>
      </div>

      <div class="p-2">
        <b-button
          variant="btn btn-success"
          @click="addUsuario()"
        > Novo Usuário</b-button>
      </div>

    </div>

    <!-- <div class="col-lg-12">
      <b-jumbotron>
        <div class="tituloCoop">
          <h3>Filtrar usuário {{resultadoFiltro}}</h3>
        </div>

        <div class="form-row">

          <div class="form-group  col-lg-6">
            <label><span class="text-danger"></span> Nome</label>
            <input
              class="form-control "
              v-model="buscaNome"
            >
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> E-mail</label>
            <input
              class="form-control"
              v-model="buscaEmail"
            >
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> CPF</label>
            <input
              class="form-control"
              v-model="buscaCpf"
            >
          </div>

        </div>

      </b-jumbotron>
    </div> -->
    <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

    <b-jumbotron bg-variant="light">
      <div class="tabela">
          <b-table striped hover :items="items" class="text-center" :fields="fields">
              <template #cell(Editar)="data">
                  <img src="../assets/edit1.png" alt="editar" height="23px" @click="editarUser(data.item)">                       
              </template>    
              <template #cell(Apagar)="data">
                  <img src="../assets/del4.png" alt="editar" height="23px" @click="showModal(data.item)">                       
              </template>    
          </b-table>
      </div>


    </b-jumbotron>



    <!--MODAL DE CONFIRMAÇÃO-->
      <b-modal ref="my-modal" hide-footer title="Você confirma a exclusão? ">
        <b-button class="mt-3" variant="outline-success" block @click="apagarUser()">Sim</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="hideModal()">Não</b-button>
      </b-modal>

  </div>

</template>


<script>
import api from "../../services/api";

export default {
  name: "UserList",

  data() {
    return {
      idUsuario: null,
      resultadoFiltro: '',
      buscaNome: "",
      buscaEmail: "",
      buscaCpf: "",

  
      items: [],

      fields: [
          {key: 'id', label: 'ID', formatter: 'id'},
          {key: 'nome', label: 'Nome', formatter: 'nome'},
          {key: 'email', label: 'E-mail', formatter: 'email'},
          {key: 'cpf', label: 'CPF', formatter: 'cpf'},
          {key: 'data_create', label: 'Data criação', formatter: 'data_create'},
          'Editar',
          'Apagar'
      ],

    };
  },

 

  created() {
    this.getUsuarios()
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },


    getUsuarios() {

      api
        .get("webadmin/usuarios")
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
        })
        .catch(err => {
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });

      
    },

    showModal(user) {
      // console.log('user',user)
      this.idUsuario = user.id
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    editarUser(user) {
      localStorage.setItem("user-cad", JSON.stringify(user));
      this.$router.push("/usuarios-cadastro");
    },

    addUsuario() {
      localStorage.removeItem("user-cad");
      this.$router.push("/usuarios-cadastro");
    },

    apagarUser() {

      api
        .delete("webadmin/usuarios/" + this.idUsuario)
        .then(() => {
          this.hideModal()
          this.msg("USUÁRIO", "REMOVIDO COM SUCESSO.", "success");
          this.getUsuarios()
        })
        .catch(err => {
          this.hideModal()
          this.msg("ERRO AO REMOVER", err.response.data, "danger")
        });

    },


    


  },


};
</script>


<style scoped>

#UserList {
  position: inherit;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border: solid 1px #cccc;
  padding: 18px;
  margin-top: 25px;
  margin-left: 5%;
  width: 90%;
}

img {
  cursor: pointer;
}

@media (max-width: 480px) {
  #UserList {
    margin-left: 0;
    width: 100%;
    padding: 1px;
     border: solid 1px rgba(255, 255, 255, 0.8);
  }
}

h2 {
  color: brown;
}

.contorno {
  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}



.titulo {
  font-size: 2rem !important;
}

h2 {
  color: brown;
}



.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-top: 5px;
}

table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

@media (min-width: 576px) {
}
.jumbotron {
  padding: 2rem 2rem;
}

@media (min-width: 480px) {
}
.jumbotron {
  padding: 1rem 1rem;
}



</style>


