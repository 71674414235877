import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./auth";
Vue.use(Vuex)


const store = new Vuex.Store({

    devtools: true,
    namespaced: true,
    state: {
        locais: [],
        acordeonSetores: false,
        acordeonRestantes: false,
    },


    mutations: {
        setLocais(state, valor) {

            state.locais = valor
        },
        setAcordeonSetores(state, valor) {
            state.acordeonSetores = valor
        },
        setAcordeonRestantes(state, valor) {
            state.acordeonRestantes = valor
        }
    },

    modules: {
        auth: auth
    }
})

export default store;