<template>
  <div id="coopView" class="contorno col-lg-12">

    <h2 class="titulo2">Detalhes Cooperados</h2>
    <div :v-model="status"></div>
    <!-- <b-button class="btnRight " variant="btn btn-outline-success btn-sm" link to="/coopList"> Listar</b-button> -->
    <b-button class="btnRight " variant="btn btn-outline-success btn-sm" @click="toVoltar"> Voltar</b-button>
    <b-button class="btnEditar " variant="outline-warning btn-sm" link to="/coopCad"> Editar</b-button>
  

    <hr>
    <form enctype="multipart/form-data"
          v-if="show"
          class="row g-3">

      <div class="form-row">

        <div class="page-informacoes col-lg-12">
          <h3>Dados Pessoais </h3>


          

          <div class="img_perfil text-center col-lg-12">
            <b-avatar :src="form.urlImg1" size="6rem"></b-avatar>
            <div class="status mt-2">
            Status: <span class="badge badge-success">{{ form.status }}</span>
            </div>
        </div>
        </div>

        <div class="form-group col-lg-12">
          <label><span class="text-danger">*</span> Nome</label>
          <b-form-input
              v-model="form.nome"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Data Nascimento</label>
          <b-form-input
              v-model="form.dataNasc"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> CPF</label>
          <b-form-input
              v-model="form.cpf"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Matricula</label>
          <b-form-input
              v-model="form.matricula"
          ></b-form-input>
        </div>





<!--        <div class=" clearfix col-lg-2">-->
<!--          <label><span class="text-danger">*</span>Imagem Perfil </label>-->
<!--          <img class="preview" :src="form.urlImg1">-->
<!--        </div>-->

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Documentos Pessoais--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Documentos Pessoais</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Nome da Mãe</label>
          <b-form-input
              v-model="form.nomeMae"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Nome da Pai</label>
          <b-form-input
              v-model="form.nomePai"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> RG</label>
          <b-form-input
              v-model="form.rg"
          ></b-form-input>
        </div>

        <!-- <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Digito</label>
          <b-form-input
              v-model="form.digito"
          ></b-form-input>
        </div> -->

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Data de Expedição</label>
          <b-form-input
              v-model="form.dataExp"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Genero</label>
          <b-form-input
              v-model="form.genero"
          ></b-form-input>
        </div>


        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Funções--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Funções</h3>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 1 </label>
          <b-form-select
            v-model="form.funcao1"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 2 </label>
          <b-form-select
            v-model="form.funcao2"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 3 </label>
          <b-form-select
            v-model="form.funcao3"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 4 </label>
          <b-form-select
            v-model="form.funcao4"
            :options="funcoes"
          ></b-form-select>
        </div>

        <!-- <b-card
          class="mt-3"
          header="Resultado do Form"
        >
          <pre class="m-0">{{ form }}</pre>
        </b-card> -->


        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Endereço--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Endereço</h3>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> CEP</label>
          <b-form-input
              v-model="form.cep"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Estado</label>
          <b-form-input
              v-model="form.cidade"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Região</label>
          <b-form-select
            v-model="form.regiao"
            :options="regioes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> UF</label>
          <b-form-input
              v-model="form.uf"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Endereço</label>
          <b-form-input
              v-model="form.endereco"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Numero</label>
          <b-form-input
              v-model="form.numero"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Complemento</label>
          <b-form-input
              v-model="form.complemento"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Bairro</label>
          <b-form-input
              v-model="form.bairro"
          ></b-form-input>
        </div>


        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Contato--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Contato</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Email</label>
          <b-form-input type="email"
                        v-model="form.email"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Telefone</label>
          <b-form-input
              v-model="form.telefone"
          ></b-form-input>
        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Dados Bancários--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Dados Bancários</h3>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Banco</label>
          <b-form-input
              v-model="form.banco"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Agência</label>
          <b-form-input
              v-model="form.agencia"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Conta</label>
          <b-form-input
              v-model="form.conta"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Tipo</label>
          <b-form-input
              v-model="form.tipo"
          ></b-form-input>
        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Atestado--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Atestado</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Data de Emissão</label>
          <b-form-input
              v-model="form.dataEmissao"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Validade</label>
          <b-form-input
              v-model="form.validade"
          ></b-form-input>
        </div>

        <div class="bloco1 col-lg-12">
          <div class="upload-form col-lg-6">

            <div class="mt-3" style="text-align: center">
              <label><span class="text-danger">*</span> Imagem Atestado
                </label>
              <b-img class="foto" :src="form.urlImg2"></b-img>
            </div>
          </div>

          <!-- <div class="bloco3 col-lg-6">
            <div class="form-group col-lg-5">
              <label><span class="text-danger">*</span> Atestado</label>
              <b-form-input
                  v-model="form.atestado"
              ></b-form-input>
            </div>

            <div class="form-group col-lg-5">
              <label><span class="text-danger">*</span> Motivo</label>
              <b-form-input
                  v-model="form.motivoAtes"
              ></b-form-input>
            </div>
          </div> -->


        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Foto com Uniforme--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Foto com Uniforme</h3>
        </div>

        <!-- <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Data de Emissão</label>
          <b-form-input
              v-model="form.dataEmissao2"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Validade</label>
          <b-form-input
              v-model="form.validadeUnif"
          ></b-form-input>
        </div> -->


        <div class="bloco1 col-lg-12">
          <div class=" col-lg-6">
            <div class="mt-3" style="text-align: center">
              <label><span class="text-danger" style="margin-left: -12%;">*</span> Imagem Uniforme {{
                  imageTres
                }}</label>
              <b-img class="foto" :src="form.urlImg3"></b-img>
            </div>
          </div>

          <!-- <div class="bloco3 col-lg-6">
            <div class="form-group col-lg-5">
              <label><span class="text-danger">*</span> Uniforme</label>
              <b-form-input
                  v-model="form.uniforme"
              ></b-form-input>
            </div>

            <div class="form-group col-lg-5">
              <label><span class="text-danger">*</span> Motivo</label>
              <b-form-input
                  v-model="form.motivoUnif"
              ></b-form-input>
            </div>
          </div> -->
        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Antecedentes Criminais--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Antecedentes Criminais</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Data</label>
          <b-form-input
              v-model="form.data"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Validade</label>
          <b-form-input
              v-model="form.validadeCri"
          ></b-form-input>
        </div>


        <div class="bloco1 col-lg-12">
          <div class=" col-lg-6">
            <div class="mt-3" style="text-align: center">
              <label><span class="text-danger" style="margin-left: -12%;">*</span> Imagem Uniforme {{
                  imageQuatro
                }}</label>
              <b-img class="foto" :src="form.urlImg4"></b-img>
            </div>
          </div>
               <!-- <a href="form.urlImg4" target="_blank" :src="form.urlImg4" alt=""></a> -->

          <div class="bloco3 col-lg-6">

            <!-- <div class="form-group col-lg-5">
              <label><span class="text-danger">*</span> Antecedentes</label>
              <b-form-input
                  v-model="form.antecedentes"
              ></b-form-input>
            </div>

            <div class="form-group col-lg-5">
              <label><span class="text-danger">*</span> Motivo</label>
              <b-form-input
                  v-model="form.motivoAnt"
              ></b-form-input>
            </div> -->
          </div>
        </div>

      </div>

    </form>
  </div>


</template>

<script>

// import firebase from '../services/firebaseConnection';
import api from "../../services/api";
// import axios from "axios";

export default {
  name: "CoopView",

  data() {
    return {
      items: [],

      file1: null,
      status: [],
      motivoAtes: [],
      motivoUnif: [],
      motivoAnt: [],
      genero: [],
      funcoes: [],
      regioes: [
        'Norte', 'Nordeste', 'Sul', 'Sudeste', 'Centro-Oeste'
      ],


      form: {
        funcao1: null,
        funcao2: null,
        funcao3: null,
        funcao4: null,
      },

      selected: "atestado",
      options1: [],

      selected2: "uniforme",
      options2: [],

      selected3: "antecedentes",
      options3: [],
      show: true
    };
  },

  async created() {
    
    const cooperado = await localStorage.getItem('cooperado')
    this.form = JSON.parse(cooperado)

    await this.getFuncoes()
    await this.getFuncoesDoProfissional()
    await this.getRegioes()
  },

  methods: {


    async getRegioes() {

      const res = await api.get("webadmin/regioes")
      this.regioes = res.data

    },
    
    async getFuncoes() {

      try {

        const res = await api.get("funcoes")
        this.funcoes = res.data;
        console.log('RESULT', res.data)
        
      } catch(error) {
        this.msg("ERRO AO BUSCAR FUNÇÕES", error.response.data, "danger") 
      }

    },

    async getFuncoesDoProfissional() {

      try {

        const res = await api.get(`webadmin/cooperados/funcoes/${this.form.cooperativa}/${this.form.matricula}`)
        this.form.funcao1 = res.data.funcao1;
        this.form.funcao2 = res.data.funcao2;
        this.form.funcao3 = res.data.funcao3;
        this.form.funcao4 = res.data.funcao4;

        console.log('funcoes',res.data)
        
      } catch(error) {
        this.msg("ERRO AO BUSCAR FUNÇÕES DO PROFISSIONAL", error.response.data, "danger")
      }

    },

     editar(item) {
      const cooperado = JSON.stringify(item);
      localStorage.setItem("cooperado", cooperado);
      this.$router.push("/coopCad");
    },

    toVoltar(){
      // this.$router.pop();
      this.$router.go(-1) 
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();

      this.form.idCooperado = localStorage.getItem('idCooperado');


      api.post('webadmin/cooperados', this.form)
          .then(res => {
            this.msg('COOPERADO', 'CADASTRADO COM SUCESSO.', 'success')
            localStorage.setItem('idCooperado', res.data.idCooperado);
            this.onReset()
            this.getCooperado()
          })
          .catch(err => this.msg('ERRO AO CADASTRAR COOPERADO', err.response.data, 'danger'))
    },


  }
};

</script>


<style scoped>

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #cc1f7a !important;
  background-color: #cc1f7a
}

.contorno {
  border: solid 1px #CCC;
  padding: 1rem;
  margin-top: 25px;
  margin-right: 10px;
  margin-bottom: 25px;
  margin-left: 5%;
  width: 90%;
}

@media (max-width: 480px) {
  .contorno {
 
        margin-left: 0%;
    width: 100% !important;
     border: solid 1px rgba(255, 255, 255, 0.8);
  }
}

.upload-form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px;
}

img.preview {
  width: 200px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}

img.foto {
  width: 200px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}


.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
}

.btnEditar {

  float: right;
  margin-top: -40px;
  margin-right: 80px;
}

@media screen and (max-width: 480px) {
  .btnRight {
    width: 100%;
    float: none;
    margin-top: 0px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .btnEditar {
    width: 100%;
    float: none;
    margin-top: 0px;
  }
}

.bloco1 {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 480px) {
  .bloco1 {
    width: 100%;
    flex-direction: column;
  }
}

.tituloCoop {
  width: 100%;
  float: left !important;
  margin-top: 10px;
  margin-bottom: 25px;

}

.titulo2 {
  display: flex;
  margin-right: 0;

}

.linhaVermelha {
  width: 100%;
  border-bottom: 1px solid #f21d1d !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-12, .col-lg-2, .col-lg-3, .col-lg-6, .col-md-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.page-informacoes {
  margin-bottom: 25px;
}


.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #cc1f7a;
  background-color: #cc1f7a;
}

.img_perfil {
  margin-top: -3rem;
}

@media (max-width: 480px) {
  .img_perfil {
        margin-top: 0rem;
  }
}

</style>