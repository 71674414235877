

<template> 
    <div>
        <center> 
         <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Selecione o arquivo..."
            drop-placeholder="Jogue o arquivo aqui..."
            @change="loadTextFromFile"
          ></b-form-file> 
          <hr>
                        
            <b-button class="btn-right btn-footer" variant="btn btn-outline-success btn-sm" @click="AtualizaFaturamentos()" > Importar</b-button>
  
        </center>
    </div> 
</template>
 
<script>
import { isDate } from 'moment';
  import api from "@/services/api"; 
 

  export default { 
    name: 'ModalImportaTxt',  
    data() {
      return {   
        file: null,
        faturamentos:[],
        txtFileFats:[]
      }
    },

    created() {    
      console.log("FaturamentoEdit -> [created]");    

    }, 

    methods: {  
      AtualizaFaturamentos(){  
        this.faturamentos.forEach(fat => {
          api.put('webadmin/faturamentos/' + fat.id, fat) 
          .then(() => { console.log('Faturamento', 'Atualizado com sucesso.', 'success') })
          .catch(err => console.log('Erro ao atualizar faturamento.', err )) 
        });
      },
      
        loadTextFromFile(ev){

          const file =  ev.target.files[0];
 
          const reader = new FileReader();

          if (file.name.toLowerCase().includes(".txt")) {
            reader.onload = (res) => {

              var rps = null;
              var ccm = null;
              var nota_fiscal = null;
              var data_emissao = null;
              var codigo_verificacao_nf = null;
              var link_nf = null;

              let text = res.target.result;

              let lines = text.split(/\r?\n/); 

              for (var key in lines){ 

                //verifica se a linha e um RPS retorno
                if(lines[key].slice(0,1) === "2"){

                  //rps
                  rps = Number.parseInt(lines[key].slice(50, 61).toString());
                  //ccm
                  ccm =  Number.parseInt(lines[key].slice(1, 9).toString());

                  if(isNaN(ccm)){
                    self.loadTextFromFile = false;
                    return
                  }

                  //codigo nfse
                  nota_fiscal = Number.parseInt(lines[key].slice(9,17).toString());

                  if(isNaN(nota_fiscal)){
                    self.loadTextFromFile = false;
                    return
                  }


                  //data
                  let dateString =  lines[key].slice(17,21) + '-' +
                                    lines[key].slice(21,23) + '-' +
                                    lines[key].slice(23,25) + 'T' +
                                    lines[key].slice(25,27) + ':' +
                                    lines[key].slice(27,29) + ':' +
                                    lines[key].slice(29,31);

                  data_emissao = new Date(dateString);

                  if(!isDate( data_emissao)){
                    self.loadTextFromFile = false;
                    return
                  }

                  
                  //codigo verificacao
                  codigo_verificacao_nf = lines[key].slice(31,39).toString();

                  //link
                  link_nf = "https://nfe.prefeitura.sp.gov.br/contribuinte/notaprint.aspx?";
                  link_nf +="ccm="+ ccm + "&nf=" + nota_fiscal + "&cod=" + codigo_verificacao_nf;
  
                  this.txtFileFats.push({ccm, nota_fiscal,codigo_verificacao_nf, data_emissao, link_nf, rps})

                }
                
                this.txtFileFats.forEach(txtFat => {
                  api.get('webadmin/faturamentos/' + txtFat.rps)
                  .then(res => { 
  
                    var fat = Object.assign(res.data); 
 
                    if(fat.length !== 0) { 
                      fat.nota_fiscal = txtFat.nota_fiscal
                      fat.data_emissao = txtFat.data_emissao
                      fat.codigo_verificacao_nf = txtFat.codigo_verificacao_nf
                      fat.ccm = txtFat.ccm
                      fat.link_nf = txtFat.link_nf

                      this.faturamentos.push(fat);
                      //this.AtualizaFaturamento(fat);
                    }
                     
                  })
                  .catch(err => this.msg('Erro ao carregar Faturamento', err , 'danger'))
                  
                });


              }
                
            };
            reader.onerror = (err) => console.log(err);
            reader.readAsText(file);
          } 
          else { 
            reader.onload = (res) => {
              console.log(res.target.result);
            };
            reader.onerror = (err) => console.log(err);
            reader.readAsText(file);
          }
        }  
    } 
  };
</script>
 
     
<style scoped> 
 
</style>