<template>
  <div id="clienteLocais">
    <!-- <h2 class="titulo2">Novo Local</h2> -->
    <slot></slot>
    <form
        @submit="onSubmit"
        @reset="onReset"
        v-if="show">
      <div class="form-row">


        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Nome da unidade</label>
          <b-form-input
              class="text-uppercase"
              id="input-1"
              v-model="form.nome"
              

          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Responsável</label>
          <b-form-input
              id="input-2"
              v-model="form.responsavel"

          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> E-mail</label>
          <b-form-input
              id="input-1"
              v-model="form.email"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Telefone</label>
          <b-form-input
              id="input-2"
              v-model="form.telefone"
              v-mask="'(##) #####-####'"
          ></b-form-input>
        </div>



        <div class="form-group col-lg-1">
          <label><span class="text-danger">*</span> Cep</label>
          <b-form-input
              id="input-4"
              v-model="form.cep"
              v-mask="'#####-###'"
              @keyup="buscarCep"

          ></b-form-input>
        </div>

        <div class="form-group col-lg-2">
          <label><span class="text-danger"></span> Região</label>
          <b-form-select
            v-model="form.regiao"
            :options="regioes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Endereço</label>
          <b-form-input
              id="input-3"
              v-model="form.endereco"

          ></b-form-input>
        </div>


        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Numero</label>
          <b-form-input
              id="input-5"
              v-model="form.numero"

          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Complemento</label>
          <b-form-input
              id="input-6"
              v-model="form.complemento"

          ></b-form-input>
        </div>

        <div class="col-lg-12">
          <b-form-group label="QR Code fixo para esta unidade?">
            <b-form-radio-group
                v-model="form.qr_code_fixo"
                :options="optionsQrCodeFixo"
                name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </div>



        <div class="form-group col-lg-3" v-if="insertDB">
          <b-button class="col-md-5 m-1" type="submit" variant="success">Salvar</b-button>
        </div>

        <div class="form-group col-lg-3" v-else>
          <b-button class="col-md-5 m-1" type="submit" variant="warning">Atualizar</b-button>
          <b-button class="col-md-5 m-1" type="submit" variant="danger" @click="insertDB=true; form={}">Cancelar</b-button>
        </div>


        <!--        <b-button class="col-md-1" @click="getLocais" variant="success m-3">Enviar</b-button>-->
      </div>
    </form>

<!--    <b-card-->
<!--        class="mt-3"-->
<!--        header="Resultado do Form">-->
<!--      <pre class="m-0">{{ form }}</pre>-->
<!--    </b-card>-->

    <div>
        <b-modal ref="my-modal" hide-footer title="">
            <div class="d-block text-center">
                <h3>Você confirma a exclusão?</h3>
            </div>
            <b-button class="mt-3" variant="outline-success" block @click="apagar">Sim</b-button>
            <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
        </b-modal>
    </div>


    <div class="linhaVermelha"></div>


    <div class="marginTopo">
      <b-table striped responsive hover :fields="fields" :items="items">
        <template v-slot:cell(actions)="data">
          <b-button variant="outline-warning btn-sm" class="mr-2" @click="editar(data.item)">Editar</b-button>
          <b-button variant="outline-danger btn-sm" class="mr-2" @click="showModal(data.item)">Apagar</b-button>
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
import api from "../../services/api";
import axios from "axios";


export default {
  name: "ClienteLocais",
  data() {
    return {

      insertDB: true,


      items: [],

      regioes: [
        'Norte', 'Nordeste', 'Sul', 'Sudeste', 'Centro-Oeste'
      ],

      optionsQrCodeFixo: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' }
      ],

      fields: [
        {key: 'nome', label: 'Nome do local'},
        {key: 'responsavel', label: 'Responsável'},
        {key: 'endereco', label: 'Endereço'},
        {key: 'qr_code_fixo', label: 'QrCode Fixo', formatter: value => {
            if (value) return 'Sim'
            else 
            return 'Não'
        }},
        {key: 'actions', label: 'Ações'},
      ],

      form: {
        idCliente: "",
        nome: "",
        responsavel: "",
        endereco: "",
        cep: "",
        bairro: "",
        cidade: "",
        uf: "",
        numero: "",
        complemento: "",
        qr_code_fixo: false
      },
      show: true
    }
  },

  created() {

    this.getLocais()
    this.getRegioes()
  },

  methods: {

    async getRegioes() {

      const res = await api.get("webadmin/regioes")
      this.regioes = res.data

    },

    editar(item) {
      // console.log(item)
      this.form = item
      this.insertDB = false
    },

    apagar() {
        this.$refs['my-modal'].hide()

        api.delete(`webadmin/clientes/unidades/${this.form.id}`)
        .then(() => {

            this.msg('UNIDADE', 'REMOVIDO COM SUCESSO.', 'success')
            this.onReset()
            this.getLocais()
        })
        .catch(err => this.msg('ERRO AO REMOVER UNIDADE.', err.response.data, 'danger'))
    },

    showModal(data) {
        this.form.id = data.id
        // console.log(data.id)
        this.$refs['my-modal'].show()
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.form.id) {
        api.put('webadmin/cliente/unidades', this.form)
            .then(() => {
              this.msg('UNIDADE', 'ATUALIZADA COM SUCESSO.', 'success')
              this.onReset()
              this.getLocais()
              delete this.form.id
              this.insertDB = true
            })
            .catch(err => this.msg('ERRO AO ATUALIZAR UNIDADE', err.response.data, 'danger'))

      } else {
        this.form.idCliente = localStorage.getItem('idCliente');

        api.post('webadmin/cliente/unidades', this.form)
            .then(() => {
              this.msg('UNIDADE', 'CADASTRADA COM SUCESSO.', 'success')
              this.onReset()
              this.getLocais()

              this.$store.commit('setAcordeonSetores', true)
            })
            .catch(err => this.msg('ERRO AO CADASTRAR UNIDADE', err.response.data, 'danger'))
      }
    },

    buscarCep() {
      console.log('cep', this.form.cep);
      if (this.form.cep.length == 9) {
        axios.get(`https://viacep.com.br/ws/${this.form.cep}/json`)
            .then(res => {
              console.log(res.data);

              const dadosCep = res.data;
              this.form.endereco = dadosCep.logradouro;
              this.form.bairro = dadosCep.bairro;
              this.form.cidade = dadosCep.localidade;
              this.form.uf = dadosCep.uf;
            })
            .catch(err => this.msg('Erro: cep não encontrado.', err, 'danger'))
      }
    },

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    getLocais() {
      const idCliente = localStorage.getItem('idCliente');
      api.get(`webadmin/clientes/unidades/${idCliente}`)
          .then(res => {
            // console.log(res.data);
            this.items = res.data

          })
          .catch(err => this.msg('Erro: Ao buscar Locais.', err, 'danger'))
    },


    onReset() {

      // Redefina os valores de formulário
      this.form.nome = null;
      this.form.responsavel = null;
      this.form.endereco = null;
      this.form.cep = null;
      this.form.numero = null;
      this.form.complemento = null;
      this.form.email = null;
      this.form.telefone = null;
      this.form.regiao = null;
      // Limpar o estado de validação do formulário do navegador nativo
      // this.show = false;

    }
    
  }
};

</script>

<h2>Esté é componente ClienteCad</h2>


<style scoped>
#ClienteLocais {
  position: inherit;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, .125);

  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.titulo2 {
  display: flex;
  margin-right: 0;
}

.linhaVermelha {
  border-top-style: groove;
}

.marginTopo {
  margin-top: 25px;
}

label {
  float: left;
  display: inline-block;
  margin-bottom: .5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
</style>