import Vue from 'vue'
import Toasted from 'vue-toasted'

export function toastedMessage(texto, cor, tempo) {

    Vue.use(Toasted, {
        iconPack : 'material',
        theme: "toasted-primary", 
        position: "top-right", 
        fullWidth: false
    })

    Vue.toasted.show(texto, {
        icon: cor == 'verde' ? 'check' : 'error',
        duration : tempo,
        type: cor == 'verde' ? 'success' : 'error'
    });

}


export default { toastedMessage }