<template>
  <div id="Solicitacoes" class="contorno">
    <h2 class="titulo2"> Solicitações</h2>

    <div class="col-lg-12">
          <b-jumbotron>
       
              <div class="form-row">

                <div class="form-group  col-lg-3">
                  <label><span class="text-danger"></span> Cliente</label>
                  <input class="form-control"
                         v-model="buscaCliente"
                  >
                </div>

                <div class="form-group col-lg-2">
                  <label><span class="text-danger"></span> Unidade</label>
                  <input class="form-control"
                         v-model="buscaUnidade"
                  >
                </div>

                

                <div class="form-group col-lg-2">
                  <label><span class="text-danger"></span> Setor</label>
                  <input class="form-control"
                         v-model="buscaSetor"
                  >
                </div>

                <div class="form-group col-lg-3">
                  <label><span class="text-danger"></span> Gestor</label>
                  <input class="form-control"
                         v-model="buscaGestor"
                  >
                </div>

                <!-- <div class="form-group col-lg-2">
                  <label><span class="text-danger"></span> Status</label>
                  <input class="form-control"
                         v-model="buscaStatus"
                  >
                </div> -->

                <div class="form-group col-lg-2">
                  <label><span class="text-danger"></span> Status</label>
                  <b-form-select
                    v-model="status"
                    :options="statusList"
                    @change="getSolicitacoes"
                  ></b-form-select>
                </div>


                <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Data Minima</label>
          <b-form-datepicker type="date"
                             id="datepicker-dateformat1"
                             v-model="buscaDataMin"
                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                             locale="pt"
                             placeholder="Selecione a data"
          ></b-form-datepicker>
        </div>


        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Data Máxima</label>
          <b-form-datepicker type="date"
                             id="datepicker-dateformat2"
                             v-model="buscaDataMax"
                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                             locale="pt"
                             placeholder="Selecione a data"
          ></b-form-datepicker>
        </div>


        </div>

        <!-- <b-button class="btnRight" variant="btn btn-success btn-sg" @click="getSolicitacoes"> Buscar</b-button>     -->

      </b-jumbotron>
    </div>
    <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->


    <b-jumbotron bg-variant="light">
      <hr>
      <div class="tituloCoop">
        <h3>Solicitações</h3>
        <b-button class="btnRight" variant="btn btn-info btn-sg" @click="getSolicitacoes"> Atualizar lista</b-button>
      </div>   

            <b-table
              striped
              responsive
              hover
              id="my-table"
              :fields="fields"
              :items="items"
              :per-page="perPage"
              :busy="load"
              :current-page="currentPage"
              small
              outlined
            >

              <template #table-busy >
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Aguarde...</strong>
                </div>
              </template>

              <template v-slot:cell(tracarGestor)="data">       
                <b-button variant="outline-warning btn-sm" class="mr-2" @click="editar(data.item)">Trocar Gestor</b-button>
              </template>
              
              <template v-slot:cell(escala)="data">       
                <b-button variant="outline-info btn-sm" class="mr-2" @click="toEscala(data.item)">Ver Escalas</b-button>
              </template>
              
              <!-- <template v-slot:cell(ver)="data">       
                <b-button variant="outline-primary btn-sm" class="mr-2" @click="alteracao(data.id)">Ver alter</b-button>
              </template> -->
              

              <p class="mt-3">Current Page: {{ currentPage }}</p>

              
            </b-table>

            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination> -->



          
              
      
    </b-jumbotron>

  </div>
</template>

<script>
import api from "../../services/api"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'

dayjs.locale('pt-br')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("America/Bahia")

export default {
  name: "Solicitacoes",

  data() {
    return {

      
      buscaCliente: '',
      buscaUnidade: '',
      buscaSetor: '',
      buscaGestor: '',
      buscaStatus: '',
      buscaDataMin: '',
      buscaDataMax: '',
      statusList: ['todos','solicitado','em processo','alterado','escala completa',
                  'em andamento','em aprovação','em contestação','em reavaliação'],
       
      status: 'solicitado',

      items: [],

      fields: [
        {key: 'dataEvento', label: 'Data'},
        {key: 'cliente', label: 'Cliente'},
        {key: 'unidade', label: 'Unidade'},
        {key: 'nomeEvento', label: 'Nome evento'},
        {key: 'setor', label: 'Setor'},
        {key: 'funcao', label: 'Função'},
        {key: 'solicitante', label: 'Solicitante'},
        {key: 'qtdCliente', label: 'Qtd'},
        {key: 'cortesia', label: 'Cortesia'},
        {key: 'confirmado', label: 'Confirmado'},
        {key: 'countCheckIn', label: 'Check-in'},
        {key: 'entrada', label: 'Entrada'},
        {key: 'saida', label: 'Saida'},
        {key: 'status', label: 'Status'},
        {key: 'gestor', label: 'Gestor'},
        {key: 'id', label: 'id'},
        {key: 'tracarGestor', label: ''},
        {key: 'escala', label: ''},
      ]

    };
  },

  methods: {

    getSolicitacoes() {

      this.items = []
      this.load = true

      const camposParaFiltro = [{ campo: 'nenhum', valor: 'nenhum' }]
      if (this.buscaCliente.length > 0) camposParaFiltro.push({ campo: 'cliente', valor: this.buscaCliente })  
      if (this.buscaUnidade.length > 0) camposParaFiltro.push({ campo: 'unidade', valor: this.buscaUnidade })  
      if (this.buscaSetor.length > 0) camposParaFiltro.push({ campo: 'setor', valor: this.buscaSetor }) 
      if (this.buscaGestor.length > 0) camposParaFiltro.push({ campo: 'gestor', valor: this.buscaGestor })  


      const body = {
        campos: camposParaFiltro,
        status: this.status,
        dataIni: this.buscaDataMin,
        dataFim: this.buscaDataMax
      }

      api
        // .post(`webadmin/solicitacoes/${camposParaFiltro}/${this.status}/${this.buscaDataMin}/${this.buscaDataMax}`)
        .post('webadmin/solicitacoes', body)
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false
        })
        .catch(err => {
          this.load = false
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });



        //----------------------

      // api.get('webadmin/solicitacoes')
      //   .then(res => {
      //     // console.log('zzzzzzzzzzzzzzzzz',res.data);
      //     this.items = res.data;
      //   })
      //   .catch(err => console.log(err))

    },

    filtroCliente() {
      if (this.buscaCliente.length > 2) {
        this.getFiltroSolicitacoes("cliente", this.buscaCliente.toUpperCase());
      }
    },
    filtroUnidade() {
      if (this.buscaUnidade.length > 2) {
        this.getFiltroSolicitacoes("unidade", this.buscaUnidade.toUpperCase());
      }
    },
    filtroSetor() {
      if (this.buscaSetor.length > 2) {
        this.getFiltroSolicitacoes("setor", this.buscaSetor.toUpperCase());
      }
    },
    filtroGestor() {
      if (this.buscaGestor.length > 2) {
        this.getFiltroSolicitacoes("gestor", this.buscaGestor.toUpperCase());
      }
    },

    getFiltroSolicitacoes() {
      this.items = []
      this.load = true

      const camposParaFiltro = [{ campo: 'nenhum', valor: '' }]
      if (this.buscaCliente.length > 0) camposParaFiltro.push({ campo: 'cliente', valor: this.buscaCliente })  
      if (this.buscaUnidade.length > 0) camposParaFiltro.push({ campo: 'unidade', valor: this.buscaUnidade })  
      if (this.buscaSetor.length > 0) camposParaFiltro.push({ campo: 'setor', valor: this.buscaSetor }) 
      if (this.buscaGestor.length > 0) camposParaFiltro.push({ campo: 'gestor', valor: this.buscaGestor })  

      api
        .get(`webadmin/solicitacoes/filtro/${camposParaFiltro}/${this.status}/${this.buscaDataMin}/${this.buscaDataMax}`)
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false
          this.resultadoFiltro = '- '+this.items.length+' encontrados'
        })
        .catch(err => {
          this.load = false
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });
    },



    editar(socilitacao) {

      // console.log(socilitacao)
      localStorage.setItem('solicitacao', JSON.stringify(socilitacao))
      this.$router.push('/editarGestor')

    },

    toEscala(socilitacao) {

      // console.log('socilitacao', socilitacao)
      localStorage.setItem('solicitacao', JSON.stringify(socilitacao))
      this.$router.push('/solicitacoes/verEscala')

    }

  },

  computed: {

    resultadoBusca: function () {
      if (this.buscaSolicitante.trim() == '' && this.buscaUnidade.trim() == '' && this.buscaSetor.trim() == ''
      && this.buscaGestor.trim() == '' && this.buscaStatus.trim() == '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          return item.solicitante.toLowerCase().match(this.buscaSolicitante);
        })
            .filter((item) => {
              return item.unidade.toLowerCase().match(this.buscaUnidade);
            })
            .filter((item) => {
              return item.setor.toLowerCase().match(this.buscaSetor);
            })
            .filter((item) => {
              return item.gestor.toLowerCase().match(this.buscaGestor);
            })
            .filter((item) => {
              return item.status.toLowerCase().match(this.buscaStatus);
            })
            // .filter((item) => {
            //   return item.dataMin.toLowerCase().match(this.buscaDataMin);
            // })
            // .filter((item) => {
            //   return item.buscaDataMax.toLowerCase().match(this.buscaDataMax);
            // })
      }
    }

  },

  created() {


    this.buscaDataMin = dayjs().startOf('month').format('YYYY-MM-DD') 
    this.buscaDataMax = dayjs().endOf('month').format('YYYY-MM-DD') 

    this.getSolicitacoes()

  }

}
</script>


<style scoped>
h2 {
  color: brown;
}

.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
}

.contorno {
  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.tituloCoop {
  width: 100%;
  float: left !important;
  margin-top: 10px;
  margin-bottom: 25px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -33px;
    margin-left: -33px;
}

@media screen and (max-width: 480px) {
  .jumbotron {
    padding: 1%;
    margin-bottom: 2rem;
    background-color: #e9ecef15;
    border-radius: 0.3rem;
  }
}

@media (min-width: 576px){
.jumbotron {
  margin-top: 2rem;
    padding: 2rem 3rem;
}
}

.table-striped tr:hover {
  background-color: #dfdede;
}


</style>


