<template>
  <div
    id="CoopList"
    class="contorno"
  >

    <div class="d-flex">
      <div class="mr-auto p-2">
        <h2 class="display-4 titulo">Gerenciar Cooperados</h2>
      </div>

      <div class="p-2">
        <b-button
          variant="btn btn-outline-success btn-sm"
          @click="addCoop"
        > Novo Cooperado</b-button>
      </div>

    </div>

    <div class="col-lg-12">
      <b-jumbotron>
        <div class="tituloCoop">
          <h3>Filtrar Cooperado {{resultadoFiltro}}</h3>

      <div class="btnBuscar">
          <b-button
            variant="btn btn-outline-primary btn-sm"
            @click="getCooperados"
          > Buscar todos cooperados</b-button>
          </div>
        </div>

        <div class="form-row">

          <div class="form-group  col-lg-6">
            <label><span class="text-danger">*</span> Nome</label>
            <input
              class="form-control "
              @keyup="filtroNome"
              v-model="buscaNome"
            >
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> Matricula</label>
            <input
              class="form-control"
              @keyup="filtroMatricula"
              v-model="buscaMatricula"
            >
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> CPF</label>
            <input
              class="form-control"
              @keyup="filtroCpf"
              v-model="buscaCpf"
            >
          </div>

        </div>

      </b-jumbotron>
    </div>
    <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

    <b-jumbotron bg-variant="light">
      <b-table
        striped
        responsive
        hover
        id="my-table"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :busy="load"
        :current-page="currentPage"
        small
        outlined
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Aguarde...</strong>
          </div>
        </template>

        <p class="mt-3">Current Page: {{ currentPage }}</p>

        <template v-slot:cell(actions)="data">
          <b-button
            variant="outline-warning btn-sm"
            class="mr-2"
            @click="editar(data.item)"
          >Editar</b-button>
          <b-button
            variant="btn btn-outline-primary btn-sm"
            class="mr-2"
            @click="visualizar(data.item)"
          >Visualizar
          </b-button>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>

    </b-jumbotron>

  </div>

</template>


<script>
import api from "../../services/api";

export default {
  props: ["data"],
  name: "CoopList",

  data() {
    return {
      idCooperado: null,
      resultadoFiltro: '',
      load: false,
      items: [],
      buscaNome: "",
      buscaMatricula: "",
      buscaCpf: "",


      perPage: 10,
      currentPage: 1,
  

      fields: [
        { key: "matricula", label: "Matricula" },
        { key: "nome", label: "Nome" },
        { key: "sexo", label: "Genero" },
        { key: "cidade", label: "Cidade" },
        { key: "regiao", label: "Região" },
        {key: 'gestor', label: 'Gestor', formatter: value => {
            if (value) return 'Sim'
        }},
        { key: "status", label: "Status" },
        {key: 'cooperativa', label: 'Cooperativa', formatter: value => {
            if (value == 3) return 'Cooperado'
            else
            return 'Pré-Cadastro'

        }},
        { key: "actions", label: "Ações" }
      ]
    };
  },

  computed: {
    rows() {
      return this.items.length;
    }

    // resultadoBusca: function () {
    //   if (this.buscaNome.trim() == '' && this.buscaMatricula.trim() == '' && this.buscaCpf.trim() == '') {
    //     return this.items;
    //   } else {
    //     return this.items.filter((item) => {
    //       return item.nome.toLowerCase().match(this.buscaNome);
    //     })
    //     .filter((item) => {
    //           return item.matricula.toString().match(this.buscaMatricula);
    //         })
    //         .filter((item) => {
    //           return item.cpf.toLowerCase().match(this.buscaCpf);
    //         })
    //   }
    // }
  },

  created() {
    // this.getCooperados()
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    filtroNome() {
      if (this.buscaNome.length > 2) {
        this.getCooperadosFiltro("nome", this.buscaNome.toUpperCase());
      }
    },
    filtroMatricula() {
      if (this.buscaMatricula.length > 2) {
        this.getCooperadosFiltro("matricula", this.buscaMatricula.toString());
      }
    },
    filtroCpf() {
      if (this.buscaCpf.length > 2) {
        this.getCooperadosFiltro("cpf", this.buscaCpf);
      }
    },

    getCooperadosFiltro(filtro, valor) {
      this.items = []
      this.load = true

      api
        .get(`webadmin/cooperados-filtro/${filtro}/${valor}`)
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false
          this.resultadoFiltro = '- '+this.items.length+' encontrados'
        })
        .catch(err => {
          this.load = false
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });
    },

    getCooperados() {
      this.items = []
      this.load = true
      this.resultadoFiltro = '0 encontrados'
      api
        .get("webadmin/cooperados")
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false
          this.resultadoFiltro = '- '+this.items.length+' encontrados'
        })
        .catch(err => {
          this.load = false
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });

      
    },

    visualizar(item) {
      const cooperado = JSON.stringify(item);
      localStorage.setItem("cooperado", cooperado);
      this.$router.push("/coopView");
    },

    editar(item) {
      const cooperado = JSON.stringify(item);
      localStorage.setItem("cooperado", cooperado);
      this.$router.push("/coopCad");
    },

    addCoop() {
      localStorage.removeItem("cooperado");
      this.$router.push("/coopCad");
    }
  }

  // PARA A PÁGINAÇÃO rows
  // computed: {
  //   rows() {
  //     return this.items.length
  //   }
  // }
};
</script>


<style scoped>

#CoopList {
  position: inherit;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border: solid 1px #cccc;
  padding: 18px;
  margin-top: 25px;
  margin-left: 5%;
  width: 90%;
}

@media (max-width: 480px) {
  #CoopList {
    margin-left: 0;
    width: 100%;
    padding: 1px;
     border: solid 1px rgba(255, 255, 255, 0.8);
  }
}

h2 {
  color: brown;
}

.contorno {
  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.display-4 {
  float: left;
  padding: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4[data-v-043f23a8] {
  float: left;
  padding: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4[data-v-043f23a8] {
  float: left;
  padding: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.titulo {
  font-size: 2rem !important;
}

h2 {
  color: brown;
}

.display-4 {
  float: left;
  padding: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-top: 5px;
}

table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

@media (min-width: 576px) {
}
.jumbotron {
  padding: 2rem 2rem;
}

@media (min-width: 480px) {
}
.jumbotron {
  padding: 1rem 1rem;
}

.pagination {
  /* display: flex; */
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.tituloCoop {
  margin-top: -10px;
  color: rgb(59, 152, 238);
  display: flex;
  margin-bottom: 2%;
}

.btnBuscar {
margin-left: auto;
}

</style>


