<template>
  <div>

    <b-sidebar
      v-model="abrir"
      id="sidebar-no-header"
      aria-labelledby="sidebar-no-header-title"
      no-header
      shadow
      bg-variant="dark"
      text-variant="light"
      backdrop-variant="dark"
    >

      <template #default="{ hide }">
        <div class="p-3">
          <div class="d-flex align-items-center flex-column">
            <!-- <b-avatar
              variant="primary"
              text="SP"
              class="mb-3 mt-3"
              size="5rem"
            ></b-avatar> -->
            <img src="../pages/assets/logo.png" class="logo-eventos-sidebar">
            <div class="divider-menu"></div>
          </div>

          <nav class="mb-3">
            <b-nav vertical>

             <b-nav-item
                active
                link to="/dashboard"
                @click="hide"
              >
                <b-icon icon="bar-chart-fill"></b-icon> Dashboard
              </b-nav-item>


              <!-- <b-nav-item
                link to="/cooperado"
                @click="hide"
              > 
                 <b-icon icon="person"></b-icon>Cooperado
              </b-nav-item>

              <b-nav-item
                link to="/cliente"
                @click="hide"
              > 
                 <b-icon icon="person"></b-icon>Cliente
              </b-nav-item> -->


              

             <b-nav-item
                link to="/solicitacoes"
                @click="hide"
              >
                 <b-icon icon="clipboard"></b-icon>
                 Solicitações
              </b-nav-item>


              <b-nav-item-dropdown >
                <template #button-content>
                  <b-icon icon="folder-fill"></b-icon>
                  Faturamento
                </template>
                <b-dropdown-item link to="/preparacao"><b-icon icon="stopwatch"></b-icon> Preparação</b-dropdown-item>
                <b-dropdown-item link to="/emissao"><b-icon icon="cash-stack"></b-icon> Emissão</b-dropdown-item>

              </b-nav-item-dropdown>



              <b-nav-item-dropdown >
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <b-icon icon="person-fill"></b-icon>
                 Gerenciar Cadastros
                </template>
                <b-dropdown-item link to="/coopList"><b-icon icon="person"></b-icon> Cooperados</b-dropdown-item>
                <b-dropdown-item link to="/clientes"><b-icon icon="people"></b-icon> Clientes</b-dropdown-item>
              </b-nav-item-dropdown>


              <!-- <b-nav-item
                link to="/solicitacoes"
                @click="hide"
              >
                 <b-icon icon="exclamation-square-fill"></b-icon>
                 Avisos
              </b-nav-item>
 -->

               <b-nav-item-dropdown >
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <b-icon icon="gear-wide-connected"></b-icon>
                 Administração
                </template>
                <b-dropdown-item link to="/usuarios-lista"><b-icon icon="person"></b-icon> Usuários</b-dropdown-item>
                <b-dropdown-item link to="/admin/config"><b-icon icon="gear"></b-icon> Configurações</b-dropdown-item>
              </b-nav-item-dropdown>



              <b-nav-item @click="logOut">
                <b-icon icon="arrow-bar-right"></b-icon>
                 <!-- <b-icon icon="exit"></b-icon> -->
                 Sair
              </b-nav-item>


            </b-nav>
          </nav>

          <b-button
            block
            @click="hide"
            style="background-color: #555555"
          >Ocultar menu </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import api from "../services/api";

export default {
  name: "SideBarComponent",

  data() {
    return {
      abrir: false
    };
  },

  methods: {

    async logOut() {
      const confirm = window.confirm('Você realmente deseja sair?');

      if (confirm) {
        localStorage.removeItem('userDados')
        this.$store.commit('auth/setUser' , null)
        this.$store.commit('auth/toggleMenu' , false)

        delete api.defaults.headers.common['Authorization']
        this.$router.push('/login')
      } else {
        return;
      }

    }

  }

};
</script>

<style scped>
.nav-item > .nav-link {
  color: #BB9C65;
}

.nav-item > .nav-link:hover {
  color: #ccc;
}

.divider-menu {
  border: 1px solid #BB9C65;
  width: 100%;
  margin-bottom: 10px;
}

.logo-eventos-sidebar {
  width: 65%;
  display: flex;
  padding: 20px;
}

.sidebar {
  background-color: #555555;
}

</style>


