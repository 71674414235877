

<template> 
    <div>
        <center> 
         <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Selecione o arquivo..."
            drop-placeholder="Jogue o arquivo aqui..."  
            @change="loadTextFromFile"
          ></b-form-file> 
          <hr>
                        
          <b-button 
            class="btn-right btn-footer"
            :disabled="btnDisabled"
            variant="btn btn-outline-success btn-sm" 
            @click="uploadBoleto(file)"
            > Upload </b-button>
  
        </center>
    </div> 
</template>
 
<script> 
  import api from "../../../services/api";   
  import firebase from "firebase";


  export default { 
    name: 'ModalImportaTxt',  
    props: ['fatId'],
    data() {
      return {   
        file: null, 
        btnDisabled:true,
        urlPdf:null,
        faturamento:null
      }
    }, 
    created() {    
      console.log("ModalUploadBoleto -> [created]");
      this.getFaturamentoListByID();    

    },  
    methods: {   
      msg(titulo, texto, cor) {
        this.toastCount++
        this.$bvToast.toast(texto, {
          title: titulo,
          variant: cor,
          solid: true,
          autoHideDelay: 5000,
          appendToast: true
        })
      },
      
      getFaturamentoListByID(){

        console.log("ModalUploadBoleto -> [getFaturamentoListByID]");   

        api.get('webadmin/faturamentos/' + this.fatId)
        .then(res => { this.faturamento =  Object.assign(res.data);    })
        .catch(err => this.msg('Erro ao carregar Faturamento', err , 'danger')) 
      }, 

      AtualizaFaturamentos(fat){   
          api.put('webadmin/faturamentos/' + fat.id, fat) 
          .then(() => { console.log('Faturamento', 'Atualizado com sucesso.', 'success') })
          .catch(err => console.log('Erro ao atualizar faturamento.', err )) 
       
      },

      tratarNomePdf(nomePdf) { 
            const nome = nomePdf.split(".");
            const agora = Math.floor(Date.now());

            return agora + "." + nome[1];
      },

      async uploadBoleto(file) {

            if (!file) return

            console.log("file", file)

            try {
                const storageRef = firebase.storage().ref()

                this.urlPdf = null;
                const nomePdf = this.tratarNomePdf(file.name) 

                const metadata = {
                    contentType: "application/pdf"
                };

                const fileRef = storageRef.child(`boletos/${nomePdf}`)
                const uploadTaskSnapshot = await fileRef.put(file, metadata) 

                this.urlPdf = await uploadTaskSnapshot.ref.getDownloadURL();

                this.faturamento.link_boleto = this.urlPdf;

                this.AtualizaFaturamentos(this.faturamento)


            } catch (error) {
                console.log("ERR ===", error)
                //alert("Image uploading failed!")
            }

        },




      loadTextFromFile(ev){

        console.log("ModalUploadBoleto -> [loadTextFromFile]");   

        const file =  ev.target.files[0];
 

        if (file.name.toLowerCase().includes(".pdf")) {  
          this.btnDisabled= false;  
        } 
        else {   
          this.msg('Erro no upload', 'Tipo de arquivo invalido!', 'warning');  
          this.btnDisabled= true;
        }
      }  
    } 
  };
</script>
 
     
<style scoped> 
 
</style>