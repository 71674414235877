<template>
  <div id="clienteList" class="contorno">


    <div class="col-lg-12">
      
        <div class="tituloFiltro">
          <h3>Filtrar Clientes {{resultadoFiltro}}</h3>
        </div>

        <div class="form-row">

          <div class="form-group  col-lg-6">
            <label><span class="text-danger"></span> Nome</label>
            <input
              class="form-control "

              v-model="buscaNome"
            >
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Endereço</label>
            <input
              class="form-control"

              v-model="buscaEndereco"
            >
          </div>

        </div>

    
    </div>



    <h2 class="display-4 titulo">Gerenciar Clientes</h2>
    <b-button class="btnRight" variant="success" @click="addCliente"> Novo Cliente</b-button>

    <b-jumbotron bg-variant="light">

      <b-table striped responsive hover :fields="fields" :items="resultadoBusca">
        <template v-slot:cell(actions)="data">
          <b-button variant="warning btn-sm" class="mr-2" @click="editar(data.item)">Ver / Editar</b-button>
          <b-button variant="danger btn-sm" class="mr-2" @click="showModalDeletar(data.item)">Apagar</b-button>
        </template>
      </b-table>


      <!--MODAL DE CONFIRMAÇÃO-->
      <b-modal ref="my-modal"
               hide-footer title="">

        <div class="d-block text-center">
          <h2>Você confirma a exclusão? </h2>
          <pre class="m-0"> <h3><strong> {{ nome_comercial }} </strong></h3></pre>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="deletar">Sim</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
      </b-modal>

    </b-jumbotron>

  </div>
</template>

<script>
import api from '../../services/api';

export default {
  name: "ClienteList",

  data() {
    return {
      idCliente: '',
      nome_comercial:'',

      buscaNome: "",
      buscaEndereco: "",

      items: [],


      fields: [
        {key: 'id', label: 'id'},
        {key: 'nome_comercial', label: 'Nome do cliente'},
        {key: 'email', label: 'E-mail'},
        {key: 'endereco', label: 'Endereço'},
        {key: 'actions', label: 'Ações'},
      ]
    }
  },
  created() {
    this.getClientes()
  },

  computed: {
    rows() {
      return this.items.length;
    },

    resultadoBusca: function () {
      if (this.buscaNome.trim() == '' && this.buscaEndereco.trim() == '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          return item.nome_comercial.toLowerCase().match(this.buscaNome);
        })
        .filter((item) => {
              return item.endereco.toLowerCase().match(this.buscaEndereco);
            })
      }
    }
  },

  methods: {

    filtroNome() {
      if (this.buscaNome.length > 2) {
        this.getCooperadosFiltro("nome", this.buscaNome.toUpperCase());
      }
    },
    filtroEndereco() {
      if (this.buscaEndereco.length > 2) {
        this.getCooperadosFiltro("matricula", this.buscaEndereco.toUpperCase());
      }
    },

    getClientes() {
      api.get('webadmin/clientes')
          .then(res => {
            // console.log(res.data);
            this.items = res.data;
          })
          .catch(err => console.log(err))
    },

    addCliente() {
      this.$store.commit('setAcordeonSetores', false)
      this.$store.commit('setAcordeonRestantes', false)
      localStorage.removeItem('idCliente')
      this.$router.push('/cliente')
    },

    editar(item) {
      this.idCliente = item.id;
      localStorage.setItem('idCliente', item.id)
      this.$store.commit('setAcordeonSetores', false)
      this.$store.commit('setAcordeonRestantes', false)
      this.$router.push('/cliente')

    },

    deletar() {
      this.$refs['my-modal'].hide()

      api.delete('webadmin/clientes/' + this.idCliente)
      .then(() => {
        this.msg('CLIENTE', 'Removido com sucesso.', 'success')
        this.getClientes()
      })
      .catch(err => this.msg('ATENÇÃO.', err.response.data, 'danger'))

    },

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    showModalDeletar(item) {
      this.idCliente = item.id
      this.nome_comercial = item.nome_comercial
      console.log(this.idCliente)
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  }

}
</script>


<style scoped>
h2 {
  color: brown;
}

.btnRight {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
}

.contorno {
  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
  /* margin-left: 331px; */
}

.titulo {
  font-size: 2.0rem !important;
}

.display-4 {
  float: left;
  padding: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-top: 5px;
}
</style>


