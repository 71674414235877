import Dayjs from 'dayjs';
  import br from 'dayjs/locale/pt-br';
 
  import api from "@/services/api";   
  import 'vue-material/dist/vue-material.min.css'
  import 'vue-material/dist/theme/default.css' 
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/pt-br';
  import ModalFaturamentoEdit from './ModalFaturamentoEdit'; 
  import ModalArquivoRemessaSalvar from './ModalArquivoRemessaSalvar'; 
  import ModalImportaTxt from './ModalImportaTxt'; 
  import ModalUploadBoleto from './ModalUploadBoleto'; 

  export default {
    name: "Emissao",
    components: { DatePicker, ModalFaturamentoEdit, ModalArquivoRemessaSalvar, ModalImportaTxt, ModalUploadBoleto },  
    data() {
      return { 
        
        form: {
          idCliente: '0',
          idUnidade: '0',
          idSetor: '0',
        },
        
        filtro: {
          idCliente: '0',
          idUnidade: '0',
          idSetor: '0',
          status: 'gerarNF',
          periodo:[]
        },

        clientesTemp: [],
        unidadesTemp: [],

         
        clientes:{ 
          options:[],
          selected:null
        },
        unidades:{ 
          options:[],
          selected:null
        },
        setores:{ 
          options:[],
          selected:null
        },

        
          
        status: [
          {text: "Todos", value: "todos"},
          {text: "Gerar NF", value: "gerarNF"},
          //{text: "Enviar Documentos", value: "enviarDocumentos"},
          {text: "Faturados", value: "NF Emitida"}
        ],
           
        currentSort: 'id',
        currentSortOrder: 'asc',  

        eventosFields:[
          { key: 'id', label: 'Id' },
          { key: 'cliente', label: 'Cliente' },
          { key: 'unidade', label: 'Unidade' },
          { key: 'setor', label: 'Setor' },
          { key: 'nomeEvento', label: 'Nome Evento' },
          { key: 'nomeSolicitante', label: 'Solicitante' },
          { key: 'dataInicio', label: 'Data Inicio' },
          { key: 'valorSolicitado', label: 'Valor Solicitado' },
          { key: 'valor', label: 'Valor' },
          { key: 'dataAprovacao', label: 'Data Aprovação' }   
        ],
        selectMode : 'multi',
        fields: [   
          { key: 'sel', label: ' ' },
          { key: 'cliente', label: 'Cliente' , sortable: true},
          { key: 'unidade', label: 'Unidade' , sortable: true},
          { key: 'setor', label: 'Setor' , sortable: true},
          { key: 'id', label: 'RPS', sortable: true },
          { key: 'nota_fiscal', label: 'NF', sortable: true },
          { key: 'data_emissao', label: 'Emissao', sortable: true },
          { key: 'data_vencimento', label: 'Vencimento', sortable: true },
          { key: 'data_periodo_inicio', label: 'Periodo', sortable: true }, 
          { key: 'valor', label: 'Valor', sortable: true },
          { key: 'status', label: 'Status', sortable: true } ,
          { key: 'adm_fat', label: 'Adm.Fat.' } , 
          { key: 'up_doc', label: 'Up Doc' } , 
          //{ key: 'email', label: 'Email' } , 
          { key: 'detalhes', label: 'Detalhes' } , 
          { key: 'cancelar', label: 'Cancelar' } ,  
        ], 

        isBusy: false,
        faturamento:{},
        faturamentos: [],
        faturamentosSelecionados: [],
        gridSelected:[] ,
        faturamentoModal: {},

        hideFooter:true,
        fatIDBoleto:null
      };
    },

    created() {  
      
       
      var data = new Date();  
      data.setMonth( data.getMonth() -1 );

      this.filtro.periodo[0] = data;

      this.filtro.periodo[1] = new Date();  


      this.getClientes();  
      this.selecionaPeriodo();
        
    }, 

    methods: {
      
      //-------------------------------------------------
      //FUNCOES DE TELA 
      msg(titulo, texto, cor) {
        this.toastCount++
        this.$bvToast.toast(texto, {
          title: titulo,
          variant: cor,
          solid: true,
          autoHideDelay: 8000,
          appendToast: true
        })
      },
 

      selecionaPeriodo(){  
        this.getFaturamentos();
      },

      selecionaStatus(){  
          this.getFaturamentos();
      },

      //FIM FUNCOES DE TELA
      //-------------------------------------------------
      //FUNCOES DE ACESSO API

      getClientes(){

        this.unidades = []
        this.setores = []
 
        api.get(`/webadmin/clientes`)
        .then(res => { 
            const todos = [{ value: 0, text: 'TODOS'}]
            const clientes = res.data.map(item => {
              return { 
                value: item.id,  
                text: item.nome_comercial 
              } 
            })   

            this.clientes = [...todos, ...clientes]
            // result.forEach(r => { this.clientes.options.push(r); }); 
          }
        )
        .catch(err =>   console.log('Erro: Ao buscar Clientes.\n', err));
      },

      getUnidades(){

        this.unidades = []
        this.setores = []
        
        api.get(`/webadmin/clientes/unidades/${this.filtro.idCliente}`)
        .then(res => {
          //  console.log('unidades',res.data)
          const todos = [{ value: 0, text: 'TODOS'}]
          const unidades = res.data.map(item => {
              return {
                    value: item.id,
                    text: item.nome
              }
          })

          this.unidades = [...todos, ...unidades]
          this.getFaturamentos()
        })
        .catch(err =>   console.log('Erro: Ao buscar Eventos.\n', err)); 
      },

      getSetores(){
        
        api.get(`/webadmin/clientes/setores/unidade/${this.filtro.idUnidade}`)
        .then(res => {
          //  console.log('unidade',res.data)
            const todos = [{ value: 0, text: 'TODOS'}]
            const setores = res.data.map(item => {
              return {
                    value: item.id,
                    text: item.nome_setor
              }
            })

            this.setores = [...todos, ...setores]
            this.getFaturamentos()
        })
        .catch(err =>   console.log('Erro: Ao buscar Setores.\n', err)); 
      },
    



      getFaturamentos(){ 
        
        this.isBusy = true;

        if(this.filtro.periodo[0] == null && this.filtro.periodo[1] == null) this.filtro.periodo = ['0', '0']

        const params = this.filtro
        const url = `/webadmin/faturamentos/${params.idCliente}/${params.idUnidade}/${params.idSetor}/${params.status}/${params.periodo[0]}/${params.periodo[1]}` 
        // console.log('url', url)

        api.get(url) 
          .then(res => {

            this.faturamentos = res.data; 
            this.isBusy = false;
          })
          .catch(err => {
            console.log('Erro: Ao buscar Faturamentos.\n', err);
            this.isBusy = false; 
          }); 
  
      }, 

      admFaturamento(value){
        console.log(value);
        return true;
      },   

      showModalGerarNotas() {

        this.faturamentosSelecionados = this.faturamentos.filter(item => item.sel == 'true')

        if (this.faturamentosSelecionados.length == 0) {
          this.msg('ATENÇÃO!', 'Nenhuma nota-fiscal foi selecionada.', 'warning')
          return
        }

        this.$refs['modal-txtsalvar'].show()
      },

      verificarStatus(faturamento){

        if(faturamento.status == 'NF Emitida'){
          this.faturamentoModal = faturamento
          this.showModalCancelamento()
        } else {
          this.faturamentoCancelar(faturamento)
        }

      },

      faturamentoCancelar(faturamento) { 

        this.hideModalCancelamento()

        const local = localStorage.getItem('userDados')
        const user = JSON.parse(local) 
        
        faturamento.data_cancelamento = new Date();
        faturamento.status = 'Cancelado'
        faturamento.id_usuario_cancelamento = user.idUser;

        api.put('webadmin/faturamentos/cancelar/' + faturamento.id, faturamento) 
        .then(() => {
          this.msg('Faturamento', 'Cancelado com sucesso.', 'success')
          this.getFaturamentos()
        })
        .catch(err => this.msg('Erro ao cancelar faturamento.', err, 'danger'))
    
      },

      //FIM DAS FUNCOES DE API
      //-------------------------------------------------
      //FUNCOES GRID 
  
      formatDate(date){  
        
        if (date == null || date == undefined) return null
        return Dayjs(date).locale(br).format('DD-MMM-YYYY') // '25/01/2019'
      },

      formatDatePadrao(date){  
        if (date == null || date == undefined) return null
        return Dayjs(date).locale(br).format('DD-MM-YYYY') // '25/01/2019'
      },

      // setSelectable(){
      //   if(this.status.selected === "gerarNF") this.selectMode = 'multi'; 
      //   else  this.selectMode = null;
      // },
      onRowSelected(items) {
        // console.log('items', items)
        this.gridSelected = items
      },
 
      carregaFaturamentoEdit(faturamento) {  
        localStorage.setItem('faturamentoEdit', JSON.stringify(faturamento)) 
      },
       
      setIDBoleto(id) {
        this.fatIDBoleto = id;
        // console.log(this.fatIDBoleto);
      },
      openBoleto(boleto){
        window.open(boleto.link_boleto, "_blank");
      },

      //FIM DAS FUNCOES DA GRID
      //-------------------------------------------------

      showModalCancelamento(){
        this.$refs['modal-cancelamento'].show()
      },

      hideModalCancelamento(){
        this.$refs['modal-cancelamento'].hide()
      },

      verificaVencimento(faturamento) {
        // console.log(faturamento)
        if (!faturamento.item.data_vencimento) {
          faturamento.item.sel = false
          this.msg('ATENÇÃO!', 'Informe antes a data de vencimento na opção: Adm.Fat.', 'warning')
        } 
      }

    }
  };