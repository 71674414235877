<template>
  <div id="form-config">
    
    <b-list-group>

      <b-card
        class="card"
        bg-variant="light"
        text-variant="black"
        title="Cadastro do usuário"
      >
        <div>
          <b-row class="card-dentro">

            <div class="form-group col-lg-12">
              <label><span class="text-danger"></span> CPF</label>
              <b-form-input
                v-model="form.cpf"
                v-mask="'###.###.###-##'"
                @keyup="getInfosPorCpf"
                required
              ></b-form-input>
            </div>

            <div class="form-group col-lg-12">
              <label><span class="text-danger"></span> Nome</label>
              <b-form-input
                v-model="form.nome"
                required
              ></b-form-input>
            </div>
            
            
            <div class="form-group col-lg-6">
              <label><span class="text-danger"></span> E-mail</label>
              <b-form-input
                v-model="form.email"
                required
              ></b-form-input>
            </div>

            <div class="form-group col-lg-6">
              <label><span class="text-danger"></span> Confirme o e-mail</label>
              <b-form-input
                v-model="form.emailConfirme"
                required
              ></b-form-input>
            </div>

          </b-row>
        </div>

        <div v-if="botaoVisivel">

          <b-button
            href="#"
            variant="success"
            class="botao"
            @click="saveUsuario()"
           
            >Confirmar
          </b-button>
          <b-button
            href="#"
            variant="danger"
            class="botao-cancelar"
            link to="/usuarios-lista"
         
            >Cancelar
          </b-button>

        </div>
        <div class="div-loading text-center col-lg-12" v-else>
          <span class="text-aguarde">Aguarde</span>
          <b-spinner variant="info" label="Spinning"></b-spinner>
          <!-- <b-spinner variant="warning" label="Spinnir" v-if="img1"></b-spinner>
          <b-spinner variant="info" label="Spinning" v-if="img2"></b-spinner> -->
          <!--<b-spinner variant="warning" type="grow" label="Spinnir"></b-spinner>
          <b-spinner variant="info" label="Spinning"></b-spinner>
          <b-spinner variant="info" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="success" label="Spinning"></b-spinner>
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner> -->
        </div>

      </b-card>
    </b-list-group>
  </div>
</template>


<script>
import api from "../../services/api";

export default {
  data() {
    return {

      go: true,
      botaoVisivel: true,

      form: {
        nome: null,
        cpf: null,
        email: null,
        emailConfirme: null
      },
    };
  },

  methods: {
    msg(titulo, texto, cor, tempo) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: tempo,
        appendToast: true,
      });
    },

    getInfosPorCpf() {

      if (this.form.cpf.length == 14) {
 
        api
        .get('webadmin/usuarios/check-usuario/'+this.form.cpf)
        .then(res => {

          if (res.status == 209) {
            this.go = false
            this.botaoVisivel = false
            this.msg("USUÁRIO", "JÁ ESTÁ CADASTRADO COMO ADMINISTRADOR.", "warning", 4000);  
            return
          } else
          if (res.status == 210) {            
            this.go = true
            this.botaoVisivel = true
            this.form = res.data
            this.msg("USUÁRIO É UM COOPERADO.", "CONTINUE PARA TORNA-LO UM ADMINISTRADOR.", "success", 4000);  
            return
          } 
          
          this.resetForm()
          this.go = true
          this.botaoVisivel = true

        })
        .catch((err) => this.msg("ERRO AO BUSCAR DADOS", err.response.data, "danger", 5000));

      } else {
        this.go = true
        this.botaoVisivel = true
        this.resetForm()
      }
      

    },

    saveUsuario() {

      this.go = false
      this.botaoVisivel = false

      if (this.form.id) {
        api
          .put("webadmin/usuarios", this.form)
          .then(() => {
            this.msg("USUÁRIO", "ATUALIZADO COM SUCESSO.", "success", 1000);
            this.timerMsgSave(500) 
          })
          .catch((err) => {
            this.go = true
            this.botaoVisivel = true
            this.msg("ERRO AO ATUALIZAR USUÁRIO", err.response.data, "danger", 5000)
          });


      } else {
        api
          .post("webadmin/usuarios", this.form)
          .then((res) => {

            if (res.status == 210) {            
              this.msg("USUÁRIO CADASTRADO COM SUCESSO.", "NO ENTANTO, HOUVE UMA FALHA AO ENVIAR O E-MAIL. CERTIFIQUE SE O E-MAIL ESTÁ CORRETO E TENTE REENVIAR.", "success", 10000);  
              this.timerMsgSave(10000) 
            } else {
              this.msg("USUÁRIO", "CADASTRADO COM SUCESSO.", "success", 1000);
              this.timerMsgSave(500) 
            }


          })
          .catch((err) => {
            this.go = true
            this.botaoVisivel = true
            this.msg("ERRO AO ATUALIZAR USUÁRIO", err.response.data, "danger")
          });
          
      }
    },

    resetForm() {
      this.form.nome = null,
      this.form.email = null,
      this.form.emailConfirme = null
    },

    timerMsgSave(tempo) {       
      
      const timer = setInterval(() => {

          this.$router.push("/usuarios-lista");
          clearInterval(timer)

      }, tempo)

    },


  },

  created() {
      const user = JSON.parse(localStorage.getItem('user-cad'))

      // console.log('create',user)

      if (user) {
        this.form = user
      }
  }


};

</script>


<style scoped>
#form-config {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  height: 430px;
  margin-top: 40px;
  width: 100%;
}

.card-dentro{
  margin-top: 40px;
}

.botao {
  margin-top: 30px;
}

.botao-cancelar {
  margin-top: 30px;
  margin-left: 15px;
}

.text-center {
    display: flex;
    justify-content: center;
}

.text-aguarde {
    font-size: 22px;
    font-weight: 600;
    padding-right: 7px;
    color: #1c98d1;
}

</style>