import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// let firebaseConfig = {
//     apiKey: "AIzaSyDqH9S_Zwqi2F6sxsUPfebKU8nvpTyKQEc",
//     authDomain: "vueapp-c224f.firebaseapp.com",
//     databaseURL: "https://vueapp-c224f.firebaseio.com",
//     projectId: "vueapp-c224f",
//     storageBucket: "vueapp-c224f.appspot.com",
//     messagingSenderId: "451828231291",
//     appId: "1:451828231291:web:cd972bb7dd91d16f02c6de",
//     measurementId: "G-PQEBTVCJTZ"

// };

let firebaseConfig = {
    apiKey: "AIzaSyDDucfLeER-2IZ5aMoVCiD8jBZ2h4jdLdc",
    authDomain: "eventossp-69c43.firebaseapp.com",
    databaseURL: "https://eventossp-69c43.firebaseio.com",
    projectId: "eventossp-69c43",
    storageBucket: "eventossp-69c43.appspot.com",
    messagingSenderId: "307368125605",
    appId: "1:307368125605:web:a151fc4defb003016c994c",
    measurementId: "G-SYD0YZ4772"
}


/* Si não tiver conexão, ele inicializa */
if (!firebase.apps.length) {
    /* inicializa Firebase */
    firebase.initializeApp(firebaseConfig);
}


export default firebase;