<template>
  <div id="usuarios">

    <b-form
        @submit="onSubmit"
        @reset="onReset"
        v-if="show"
    >
      <div class="form-row">


        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Nome</label>
          <b-form-input
              id="input-1"
              v-model="form.nome"
              required
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> E-mail</label>
          <b-form-input type="email"
                        id="input-2"
                        v-model="form.email"
                        required
          ></b-form-input>
        </div>


        <div class="form-group col-lg-6">
          
          <b-form-group label="Unidades de acesso" label-for="tags-component-select">
            <b-form-tags
              id="tags-component-select"
              v-model="form.acesso_unidades"
              size="lg"
              class="mb-2"
              add-on-change
              no-outer-focus
            >
              <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">

                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :disabled="disabled"
                      variant="info"
                    >{{ tag }}</b-form-tag>
                  </li>
                </ul>

                <b-form-select
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  :disabled="disabled || availableOptions.length === 0"
                  :options="availableOptions"
                >
                  <template #first>
                    <!-- This is required to prevent bugs with Safari -->
                    <option disabled value="">Selecione uma ou mais unidades...</option>
                  </template>
                </b-form-select>

              </template>
            </b-form-tags>
          </b-form-group>

        </div>


        <div class="col-lg-6">
          <b-form-group label="Perfil do usuário">
            <b-form-radio-group
                v-model="form.acesso_perfil"
                :options="acessoOpcoes"
                name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <!-- <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Perfil do usuário</label>
          <b-form-select
            v-model="form.acesso_perfil"
            :options="acessoOpcoes"
          ></b-form-select>
        </div> -->





        <!-- <b-button class="col-md-1 mb-3" type="submit" :variant="corBtn">{{statusBtn}}</b-button> -->
        <div class="form-group col-lg-3" v-if="insertDB">
          <b-button class="col-md-5 m-1" type="submit" variant="success">Salvar</b-button>
        </div>

        <div class="form-group col-lg-3" v-else>
          <b-button class="col-md-5 m-1" type="submit" variant="warning">Atualizar</b-button>
          <b-button class="col-md-5 m-1" type="submit" variant="danger" @click="insertDB=true; getUsuarios(); onReset()">Cancelar</b-button>
        </div>

      </div>

<!--      <b-button @click="getUsuarios" class="col-md-1 md-5"  variant="success mb-3">Enviar</b-button>-->

    </b-form>

<!--    <b-card-->
<!--        class="mt-3"-->
<!--        header="Resultado do Form">-->
<!--      <pre class="m-0">{{ form }}</pre>-->
<!--    </b-card>-->


    <div class="linhaVermelha"></div>

    <div class="marginTopo">
      <b-table striped responsive hover :fields="fields" :items="items">
        <template v-slot:cell(actions)="data">
          <b-button variant="outline-warning btn-sm" class="mr-2" @click="editar(data.item)">Editar</b-button>
          <b-button variant="outline-danger btn-sm" class="mr-2" @click="showModal(data.item)">Apagar</b-button>
        </template>
      </b-table>
    </div>



    <!-- MODAL APAGAR -->
    <div>
      <b-modal ref="my-modal" hide-footer title="">
        <div class="d-block text-center">
            <h3>Você confirma a exclusão?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar">Sim</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
      </b-modal>
    </div>
    <!-- MODAL APAGAR FIM-->

    

  </div>
</template>


<script>
import api from "../../services/api";


export default {
  name: "Usuarios",
  data() {
    return {

      insertDB: true,

      acessoOpcoes: ['Administrativo','Padrão'],
      items: [],

      fields: [
        {key: 'nome', label: 'Usuário'},
        {key: 'email', label: 'E-mail'},
        {key: 'acesso_perfil', label: 'Perfil'},
        {key: 'acesso_unidades', label: 'Unidades Liberadas', formatter: value => { 
          
          const acessoUnidadesComNome = value?.map(acesso => {

            const achouAcesso = this.locais.filter(unidade => {
                return unidade.value == acesso
            })

            if (achouAcesso.length > 0)
            return achouAcesso[0].text
          })

          return acessoUnidadesComNome ? String(acessoUnidadesComNome) : null
        }},
        {key: 'actions', label: 'Ações'},
      ],


      locais: [],

      setores: [],

      form: {
        idCliente: '',
        nome: '',
        email: '',
        acesso_unidades: [],
        acesso_perfil: null
      },
      show: true,

      // options: ['Apple', 'Orange', 'Banana', 'Lime', 'Peach', 'Chocolate', 'Strawberry'],
      // unidadesAcesso: []
    }
  },

  created() {
    // PEGAR O ID DO CLIENTE QUE VEIO DO DADOS GERAIS, QUANDO ENVIAR A REQUISIÇÃO
    this.form.idCliente = localStorage.getItem('idCliente');

    this.getUsuarios()
    this.getUnidades()    
  },

  computed: {
    availableOptions() {
      // console.log(this.unidadesAcesso)
      return this.locais.map(item => item.text)
      .filter(item => this.form.acesso_unidades.indexOf(item) === -1)
    }
  },


  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    editar(item) {

      this.form = item
      this.preparaListaUnidadeComNome()
      this.insertDB = false
    },

    apagar() {
        this.$refs['my-modal'].hide()

        api.delete(`webadmin/clientes/usuarios/${this.form.id}/${this.form.id_user}`)
        .then(() => {

            this.msg('USUÁRIO', 'REMOVIDO COM SUCESSO.', 'success')
            this.onReset()
            this.getUsuarios()
        })
        .catch(err => this.msg('ERRO AO REMOVER USUÁRIO.', err.response.data, 'danger'))
    },

    showModal(data) {
        this.form.id = data.id
        this.form.id_user = data.id_user
        // console.log(data.id)
        this.$refs['my-modal'].show()
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },

    // getSetores() {
    //   api.get(`webadmin/clientes/usuarios/setores/${this.form.local}`)
    //       .then(res => {
    //         this.setores = res.data;

    //       })
    //       .catch(err => this.msg('Erro: Ao buscar Setores.', err, 'danger'))
    // },

    getUnidades() {
    
      const idCliente = localStorage.getItem('idCliente')
      api.get(`webadmin/clientes/unidades/${idCliente}`)
      .then(res => {

        const novaLista = res.data.map(items => {
          const unidade = {
            value: items.id,
            text: items.nome,
          }
          return unidade;
        })
        this.locais = novaLista;
      })
      .catch(err => this.msg('Erro: Ao buscar Locais.', err, 'danger'))

    },


    getUsuarios() {

      const idCliente = localStorage.getItem('idCliente');
      api.get(`webadmin/clientes/usuarios/${idCliente}`)
      .then(res => {
        this.items = res.data;
      })
      .catch(err => this.msg('Erro: Ao buscar Usuários.', err, 'danger'))
    },

    preparaListaUnidadeComId() {

      const lista = this.locais
      .filter(item => this.form.acesso_unidades.indexOf(item.text) !== -1)
      .map(item => item.value)

      this.form.acesso_unidades = lista
    },

    preparaListaUnidadeComNome() {

      const lista = this.locais
      .filter(item => this.form.acesso_unidades.indexOf(item.value) !== -1)
      .map(item => item.text)

      this.form.acesso_unidades = lista
    },


    onSubmit(evt) {
      evt.preventDefault();

      this.preparaListaUnidadeComId()

      if(this.form.id) {

        api.put('webadmin/clientes/usuarios', this.form)
        .then(() => {
          this.msg('USUÁRIO', 'ATUALIZADO COM SUCESSO.', 'success')

          // QUANDO SALVAR TRAGO DE NOVO A LISTA
          this.getUsuarios()
          this.onReset()
          delete this.form.id
          this.insertDB = true

        })
        .catch(err => this.msg('ERRO AO ATUALIZAR USUARIO', err.response.data, 'danger'))

      } else {

        api.post('webadmin/clientes/usuarios', this.form)
        .then(() => {
          this.msg('USUÁRIO', 'CADASTRADO COM SUCESSO.', 'success')

          // QUANDO SALVAR TRAGO DE NOVO A LISTA
          this.getUsuarios()
          this.onReset()
          delete this.form.id

        })
        .catch(err => this.msg('ERRO AO CADASTRAR USUARIO', err.response.data, 'danger'))
      }

    },

    onReset() {
      // Redefina os valores de formulário
      this.form = {
        idCliente: localStorage.getItem('idCliente'),
        nome: null, 
        email: null,
        acesso_unidades: [],
        acesso_perfil: null
      }
    }
  },
};


</script>

<style scoped>
#Usuarios {
  position: inherit;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, .125);

  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.linhaVermelha {
  border-top-style: groove;
}

.marginTopo {
  margin-top: 25px;
}

label {
  float: left;
  display: inline-block;
  margin-bottom: .5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
</style>