<template>
  <form @submit.prevent="doLogin()" class="form-login">

    <div class="card" v-if="validouAcesso">
      <div class="card-header text-center">
        <h1 class="mb-0">Digite sua nova senha</h1>
      </div>
      <div class="card-body">
        <div class="form-group">
          <input type="password" v-model="senha" class="form-control" placeholder="Nova senha" />
        </div>
        <div class="form-group">
          <input type="password" v-model="senhaConfirme" class="form-control" placeholder="Confirme a nova senha" />
        </div>
        <button class="btn btn-primary w-100" :disabled="loading" @click="salvarNovaSenha">
          <template v-if="loading">
            Confirmando...
            <i class="fa fa-spinner fa-spin"></i>
          </template>
          <template v-else>
            Confirmar
            <i class="fa fa-sign-in-alt"></i>
          </template>
        </button>

      </div>
    </div>

    <div v-else>
      <h1 class="mb-0">{{msgCentral}}</h1>
    </div>


  </form>
</template>

<script>
import api from "../../services/api";

export default {
  name: 'Login',

  props: ['token'],

  data: () => ({
    msgCentral: 'Acesso inválido',
    validouAcesso: false,
    senha: '',
    senhaConfirme: '',
    loading: false
  }),

  created() {

    console.log('creeeeee', this.token)
    this.validarAcesso()
  
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    validarAcesso() {

      //this.token = 'jdwbfhergvbetbgvtb'

      api
        .get(`webadmin/resetSenha/verificar/${this.token}`)
        .then(() => {
          this.validouAcesso = true
        })
        .catch(err => {
          this.validouAcesso = false
          console.log(err)
          // this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });

    },
    
    salvarNovaSenha() {

      this.loading = true

      const body = {
        senha: this.senha,
        senhaConfirme: this.senhaConfirme,
        token: this.token,
      }

      api
        .post('webadmin/resetSenha/confirmar', body)
        .then(() => {
          this.validouAcesso = false
          this.msgCentral = 'Sua senha foi alterada.'
          this.msg("SENHA", "ALTERADA COM SUCESSO.", "success")
        })
        .catch(err => {
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });

      this.loading = false

    }

    

  }

}
</script>

<style lang="scss" scoped>
.form-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 18pt;
  }
  .card {
    width: 25%;
    color: var(--darker);
  }
}

.link {
  color: var(--featured);
  text-decoration: none;
  &:hover {
    color: var(--featured-dark);
    text-decoration: underline;
  }  
}

@media (max-width: 750px) {

  .form-login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 15pt;
    }
    .card {
      width: 80%;
      color: var(--darker);
    }
  }

}

</style>
