import Vue from 'vue';
import Router from 'vue-router';
// import firebase from '../services/firebaseConnection';

import Home from '../components/views/Home';
import Dashboard from '../pages/Dashboard';
import ClienteList from '../pages/clientes/ClienteList';
import Cliente from '../pages/clientes/Cliente';
import ClienteLocais from '../pages/clientes/ClienteLocais';
import ClienteSetores from '../pages/clientes/ClienteSetores';
import Funcoes from "../pages/clientes/Funcoes";
import Gestores from "../pages/clientes/Gestores";
import Usuarios from '../pages/clientes/Usuarios';

import Solicitacoes from "../pages/solicitacoes/Solicitacoes";
import EditarGestor from "@/pages/solicitacoes/EditarGestor";
import AddProfissionais from "@/pages/solicitacoes/AddProfissionais";
import Contestacoes from "@/pages/solicitacoes/Contestacoes";


import CoopList from "../pages/cooperados/CoopList";
import CoopView from "../pages/cooperados/CoopView";
import CoopCad from "../pages/cooperados/CoopCad";
import CoopDocsPendentes from "../pages/cooperados/CoopDocsPendentes";
import AvaliacaoDosDocumentos from "../pages/cooperados/AvaliacaoDosDocumentos"

import Preparacao from "@/pages/faturamento/preparacao/Preparacao";
import Emissao from "@/pages/faturamento/emissao/Emissao";

import VerEscala from "@/pages/escala/VerEscala"

import UsuariosCad from "../pages/usuarios/Usuarios";
import UsuariosLista from "../pages/usuarios/UsuariosLista";

import Config from "../pages/administracao/Configuracoes";


import RecuperarSenhaAppProf from '../components/apps/RecuperarSenhaAppProf';

import Login from '../pages/auth/Login';
import AlterarSenha from '../pages/auth/AlterarSenha';
import NovaSenha from '../pages/auth/NovaSenha';



Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            component: Home,
            meta: {
                requiresAuth: true, //<--SÓ VAI ACESSAR SE ESTIVER LOGADO, E UMA ROTA PROTEGIDA
            }
        },

        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true,
            }
        },
        // {
        //     path: '/perfil/:userid',
        //     component: Perfil,
        //     props: true,
        //     meta: {
        //         requiresAuth: true,
        //     }
        // },

        {
            path: '/alterar-senha',
            name: 'AlterarSenha',
            component: AlterarSenha,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/nova-senha',
            name: 'NovaSenha',
            component: NovaSenha,
            meta: {
                requiresAuth: true,
            }
        },


        {
            path: '/usuarios',
            name: 'Usuarios',
            component: Usuarios,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/clientes',
            name: 'Clientes',
            component: ClienteList,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/clienteLocais',
            name: 'ClienteLocais',
            component: ClienteLocais,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/clienteSetores',
            name: 'ClienteSetores',
            component: ClienteSetores,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/funcoes',
            name: 'Funcoes',
            component: Funcoes,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/gestores',
            name: 'Gestores',
            component: Gestores,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/cliente',
            name: 'Cliente',
            component: Cliente,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/solicitacoes',
            name: 'Solicitacoes',
            component: Solicitacoes,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/solicitacoes/verEscala',
            name: 'VerEscala',
            component: VerEscala,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/editarGestor',
            name: 'EditarGestor',
            component: EditarGestor,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/solicitacoes/verEscala/addProfissionais',
            name: 'AddProfissionais',
            component: AddProfissionais,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/solicitacoes/verEscala/contestacoes',
            name: 'Contestacoes',
            component: Contestacoes,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/coopList',
            name: 'CoopList',
            component: CoopList,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/coopView',
            name: 'CoopView',
            component: CoopView,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/coopCad',
            name: 'CoopCad',
            component: CoopCad,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/coopDocsPendentes',
            name: 'CoopDocsPendentes',
            component: CoopDocsPendentes,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/avaliacaoDosDocumentos',
            name: 'AvaliacaoDosDocumentos',
            component: AvaliacaoDosDocumentos,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/preparacao',
            name: 'Preparacao',
            component: Preparacao,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/emissao',
            name: 'Emissao',
            component: Emissao,
            meta: {
                requiresAuth: true,
            }
        },


        {
            path: '/usuarios-cadastro',
            name: 'UsuariosCad',
            component: UsuariosCad,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/usuarios-lista',
            name: 'UsuariosLista',
            component: UsuariosLista,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/admin/config',
            name: 'Config',
            component: Config,
            meta: {
                requiresAuth: true,
            }
        },


        {
            path: '/app-prof/ResetPassword/verify/',
            name: 'RecuperarSenhaAppProf',
            component: RecuperarSenhaAppProf,
            props: true
        },

        


        {
            path: '/login',
            component: Login
        }
    ]
});


// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
//
//     if (requiresAuth && !firebase.auth().currentUser) {
//         next('/login');
//     } else {
//         next();
//     }
//
// })

/* AGORA VOU EM (MAIN.JS) PARA VERIFICAR SE TEM USUÁRIO LOGADO */

export default router;