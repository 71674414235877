<template>
  <div id="Gestores">

    <h2 class="titulo2">Gestores</h2>

    <b-form
        @submit="onSubmit"
        @reset="onReset"
        v-if="show"
    >
      <div class="form-row">

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Local</label>
          <b-form-select
              id="input-1"
              v-model="form.local"
              :options="locais"
              @change="getSetores"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Setor</label>
          <b-form-select
              id="input-1"
              v-model="form.setor"
              :options="setores"
              required
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Atividade</label>
          <b-form-select
              id="input-3"
              v-model="form.atividade"
              :options="atividades"
              required
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Gestor</label>
          <VSelect
              id="input-1"
              v-model="form.gestor"
              :options="gestores"
              :disabled="false"
              :searchable="true"
              :filter="fuseSearch"
              labelNotFound="Não encontrado"
              labelSearchPlaceholder="Digite o nome do profissional"
              labelTitle="Selecione"
          />
        </div>



        <!--        <VSelect-->
        <!--            v-model="selectedName"-->
        <!--            :options="names"-->
        <!--            :disabled="false"-->
        <!--            :searchable="true"-->
        <!--        />-->


        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXX--Perido do Dia--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
        <div class="col-lg-4 mt-3">
          <b-form-group label="Período do Dia">
            <b-form-radio-group
                v-model="form.periodoDia"
                :options="options1"
                name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXX--Perido do Mês--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
        <div class="col-lg-5 mt-3">
          <b-form-group label="Período do Mês">
            <b-form-radio-group
                v-model="form.periodoMes"
                :options="options2"
                name="radio-inline2"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
      </div>

      <b-button
          class="col-md-1 mb-3"
          type="submit"
          :variant="corBtn">{{statusBtn}}
      </b-button>


    </b-form>

<!--    <b-card-->
<!--        class="mt-3"-->
<!--        header="Resultado do Form"-->
<!--    >-->
<!--      <pre class="m-0">{{ form }}</pre>-->
<!--    </b-card>-->



    <div>
        <b-modal ref="my-modal" hide-footer title="">
            <div class="d-block text-center">
                <h3>Você confirma a exclusão?</h3>
            </div>
            <b-button class="mt-3" variant="outline-success" block @click="apagar">Sim</b-button>
            <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
        </b-modal>
    </div>



    <div class="linhaVermelha"></div>

    <div class="marginTopo">
      <b-table
          striped
          responsive
          hover
          :fields="fields"
          :items="items"
      >
        <template v-slot:cell(actions)="data">
          <b-button
              variant="outline-warning btn-sm"
              class="mr-2"
              @click="editar(data.item)"
          >Editar
          </b-button>
          <b-button
              variant="outline-danger btn-sm"
              class="mr-2"
              @click="showModal(data.item)"
          >Apagar
          </b-button>
        </template>
      </b-table>
    </div>

  </div>

</template>


<script>
import VSelect from '@alfsnd/vue-bootstrap-select'
import api from '../../services/api';


export default {
  name: "Gestores",

  components: {
    VSelect
  },

  data() {
    return {
      options: [],

      statusBtn: 'Salvar',
      corBtn: 'success',

      items: [],

      fields: [
        {key: "nomeLocal", label: "Local"},
        {key: "nomeSetor", label: "Setor"},
        {key: "nomeAtividade", label: "Atividade"},
        {key: "gestor.text", label: "Gestor"},
        {key: "periodoDia", label: "Período Dia"},
        {key: "periodoMes", label: "Período Mês"},
        {key: "actions", label: "Ações"},
      ],

      form: {
        local: null,
        setor: null,
        atividade: null,
        gestor: {},
        periodoDia: null,
        periodoMes: null
      },

      setores: [],

      gestores: [],

      atividades: [],

      selected: "periodoDia",
      options1: [
        {text: "Integral", value: "integral"},
        {text: "Manhã", value: "manhã"},
        {text: "Tarde", value: "tarde"}
      ],

      selectedMes: "periodoMes",
      options2: [
        {text: "Mês Inteiro", value: "inteiro"},
        {text: "1º quinzena", value: "1º quinzena"},
        {text: "2º quinzena", value: "2º quinzena"},
      ],
      show: true
    };
  },

  created() {

    this.getLocais(),
    this.getProfissionais(),
    this.getAtividades(),
    this.getGestores()

  },


  methods: {

    fuseSearch(options, search) {
     console.log(options, search)
    },

    editar(item) {
      this.form = item
      this.statusBtn = 'Atualizar'
      this.corBtn = 'warning'
      this.getSetores()
    },

    apagar() {
        this.$refs['my-modal'].hide()

        api.delete('webadmin/clientes/gestores/'+this.form.id)
        .then(() => {

            this.msg('GESTOR', 'REMOVIDO COM SUCESSO.', 'success')
            this.getGestores()
        })
        .catch(err => this.msg('ERRO AO REMOVER O GESTOR.', err.response.data, 'danger'))
    },

    showModal(data) {
        this.form.id = data.id
        // console.log(data.id)
        this.$refs['my-modal'].show()
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },

    getGestores() {
      //PEGAR O ID DO CLIENTE
      const idCliente = localStorage.getItem('idCliente');
      api.get(`webadmin/clientes/gestores/${idCliente}`)
          .then(res => {
            // console.log(res.data);
            this.items = res.data;
          })
          .catch(err => this.msg('Erro: Ao buscar Gestores.', err, 'danger'))
    },

    getAtividades() {
      api.get('webadmin/clientes/atividades')
          .then(res => {
            // console.log('Atividades', res.data);
            this.atividades = res.data
          })
          .catch(err => this.msg('Erro: Ao buscar Atividades.', err, 'danger'));
    },

    getLocais() {
      const idCliente = localStorage.getItem('idCliente');
      api.get(`webadmin/clientes/unidades-sel/${idCliente}`)
          .then(res => {
            this.locais = res.data;
          })
          .catch(err => this.msg('Erro: Ao buscar Locais.', err, 'danger'))
    },

    getProfissionais() {
      api.get('webadmin/clientes/profissionais')
          .then(res => {
            // console.log('Profissionais',res.data);
            this.gestores = res.data
          })
          .catch(err => this.msg('Erro: falha ao buscar a lista de profissionais.', err, 'danger'))
    },

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    getSetores() {
      api.get(`webadmin/clientes/usuarios/setores/${this.form.local}`)
          .then(res => {
            this.setores = res.data;

          })
          .catch(err => this.msg('Erro: Ao buscar Setores.', err, 'danger'))
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.form.id) {
        this.form.idCliente = localStorage.getItem('idCliente');

        api.put('webadmin/clientes/gestores', this.form)
            .then(() => {
              this.msg('GESTORES', 'ATUALIZADO COM SUCESSO.', 'success')
              this.onReset()
              this.getGestores()
              delete this.form.id
              this.statusBtn = 'Salvar'
              this.corBtn = "success"
            })
            .catch(err => this.msg('ERRO AO ATUALIZAR GESTORES', err.response.data, 'danger'))
      } else {


        this.form.idCliente = localStorage.getItem('idCliente');

        api.post('webadmin/clientes/gestores', this.form)
            .then(() => {
              this.msg('GESTORES', 'CADASTRADO COM SUCESSO.', 'success')
              this.onReset()
              this.getGestores()
            })
            .catch(err => this.msg('ERRO AO CADASTRAR GESTORES', err.response.data, 'danger'))
      }
    },

    onReset() {
      // Redefina os valores de formulário
      this.form.local = null;
      this.form.gestor = {};
      this.form.setor = null;
      this.form.atividade = null;
      this.form.periodoDia = null;
      this.form.periodoMes = null;
    }
  }
};
</script>

<style scoped>
.cooperado {
  display: flex;
  margin-left: 8px;
  margin-top: -163px;
  margin-bottom: 25px;
}

.titulo2 {
  display: flex;
  margin-right: 0;
}

label {
  float: left;
  display: inline-block;
  margin-bottom: .5rem;
}

.linhaVermelha {
  border-top-style: groove;
}

.marginTopo {
  margin-top: 25px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;


}

.b-form-btn-label-control.form-control {
  background-image: none;
  padding: 0;
  width: 62%;
  margin: raiht;
  margin-left: 20%;
  margin-bottom: 8%;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
</style>