<template>

  <div class="borda mx-auto">
    <div id="clienteCad">


      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">

          <b-button
              class="botao-voltar"
              type="reset"
              variant="warning m-2"
              @click="cancelar()"
          >Voltar
          </b-button>

          
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.dados-gerais variant="info">DADOS GERAIS</b-button>
          </b-card-header>

          <b-collapse id="dados-gerais" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>

              <th scope="row" colspan="5" v-if="idCliente === id_cliente">
              <h2 class="titulo2">Editar Cliente</h2> </th>
               <th scope="row" colspan="5"  v-else >
              <h2  class="titulo2" >Novo Cliente</h2>

              </th>
              <b-form
                  @submit="onSubmit"
                  @reset="onReset"
                  v-if="show"
              >
                <div class="form-row">

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> Nome comercial</label>
                    <b-form-input
                        id="input-1"
                        v-model="form.nomeComercial"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> Razão Social</label>
                    <b-form-input
                        id="input-3"
                        v-model="form.razaoSocial"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger">*</span> CNPJ</label>
                    <b-form-input
                        id="input-2"
                        v-model="form.cnpj"
                        v-mask="'##.###.###/####-##'"

                    ></b-form-input>
                  </div>


                  <div class="form-group col-lg-6">
                    <label><span class="text-danger"></span> Cep</label>
                    <b-form-input
                        id="input-6"
                        v-model="form.cep"
                        v-mask="'#####-###'"
                        @keyup="buscarCep()"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger"></span> E-mail</label>
                    <b-form-input type="email"
                                  id="input-4"
                                  v-model="form.email"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Endereço</label>
                    <b-form-input
                        id="input-4"
                        v-model="form.logradouro"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-md-2">
                    <label><span class="text-danger"></span> Numero</label>
                    <b-form-input
                        id="input-5"
                        v-model="form.numero"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-3">
                    <label><span class="text-danger"></span> Telefone1</label>
                    <b-form-input
                        id="input-7"
                        v-model="form.telefone1"
                        v-mask="'(##) #####-####'"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-3">
                    <label><span class="text-danger"></span> Telefone2</label>
                    <b-form-input
                        id="input-8"
                        v-model="form.telefone2"
                        v-mask="'(##) #####-####'"

                    ></b-form-input>
                  </div>


                  <div class="form-group col-lg-6">
                    <label><span class="text-danger"></span> Complemento</label>
                    <b-form-input
                                  id="input-4"
                                  v-model="form.complemento"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-6">
                    <label><span class="text-danger"></span> Dominio da empresa</label>
                    <b-form-input
                        id="input-9"
                        v-model="form.dominioEmp"

                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-3">
                    <label><span class="text-danger"></span> Bairro</label>
                    <b-form-input
                        id="input-9"
                        v-model="form.bairro"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-2">
                    <label><span class="text-danger"></span> Cidade</label>
                    <b-form-input
                        id="input-9"
                        v-model="form.cidade"
                    ></b-form-input>
                  </div>

                  <div class="form-group col-lg-1">
                    <label><span class="text-danger"></span> UF</label>
                    <b-form-input
                        id="input-9"
                        v-model="form.uf"
                    ></b-form-input>
                  </div>

                </div>

                <hr>

                <th scope="row" colspan="5">
                  <h2  class="titulo2" >Faturamento</h2>
                </th>

                <div class="form-row">
                  <div class="col-lg-6">
                    <div class="form-row">

                      <div class="form-group col-md-6">
                        <label><span class="text-danger"></span> Meio de recebimento</label>
                        <b-form-select
                            id="input-1"
                            v-model="form.meioRecebimento"
                            :options="meiosRecebimento"
                            required
                        ></b-form-select>
                      </div>

                      <div class="form-group col-lg-6">
                        <label><span class="text-danger"></span> Optante do Simples Nacional?</label>
                        <b-form-select
                            id="input-1"
                            v-model="form.optanteSimplesNacional"
                            :options="optanteSimplesNacional"
                            required
                        ></b-form-select>
                      </div>

                      <div class="form-group col-md-6">
                        <label><span class="text-danger"></span> Data de Inicio do Contrato</label>
                        <b-form-datepicker id="input-contratoDataInicio" 
                            v-model="form.contratoDataInicio" 
                            placeholder="__/__/____"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        >
                        </b-form-datepicker>
                      </div>

                      <div class="form-group col-lg-6">
                        <label><span class="text-danger"></span> Data Final do Contrato</label>
                        <b-form-datepicker id="input-contratoDataFim" 
                            v-model="form.contratoDataFim" 
                            placeholder="__/__/____"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        >
                        </b-form-datepicker>
                      </div>

                      <div class="form-group col-md-6">
                        <label><span class="text-danger"></span> Lista de presença</label>
                        <b-form-select
                            id="input-1"
                            v-model="form.listaPresenca"
                            :options="listaPresenca"
                            required
                        ></b-form-select>
                      </div>

                      <div class="form-group col-lg-6">
                        <label><span class="text-danger"></span> Situação Tributária</label>
                        <b-form-select
                            id="input-1"
                            v-model="form.situacaoTributaria"
                            :options="situacaoTributaria"
                            required
                        ></b-form-select>
                      </div>

                    </div>
                  </div>

                  <div class="form-group col-lg-6">
                      <label><span class="text-danger"></span> Orientações para o faturamento</label>
                      <b-form-textarea 
                              rows="7"
                              v-model="form.orientacoesFaturamento"
                      ></b-form-textarea>
                  </div>

                </div>


                <b-button
                    class="col-md-1"
                    type="submit"
                    variant="success m-3"
                >Salvar
                </b-button>


                <b-button
                    type="reset"
                    variant="danger m-3 "
                    @click="cancelar()"
                >Cancelar
                </b-button>

              </b-form>

            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1" v-if="acordeonLocais">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button @click="abriLocais" block v-b-toggle.locais variant="info">LOCAIS</b-button>
          </b-card-header>
          <b-collapse id="locais" :visible="acordeonLocais" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ClienteLocais/>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1" v-if="acordeonSetores">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button @click="abriSetores" block v-b-toggle.setores variant="info">SETORES</b-button>
          </b-card-header>
          <b-collapse id="setores" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="telaSetores">
              <ClienteSetores/>
            </b-card-body>
          </b-collapse>
        </b-card>


        <b-card no-body class="mb-1" v-if="acordeonRestantes">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.funcoes variant="info">FUNÇÕES</b-button>
          </b-card-header>
          <b-collapse id="funcoes" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <Funcoes/>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1" v-if="acordeonRestantes">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.usuarios variant="info">USUÁRIOS</b-button>
          </b-card-header>
          <b-collapse id="usuarios" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <Usuarios/>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1" v-if="acordeonRestantes">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.gestores variant="info">GESTORES</b-button>
          </b-card-header>
          <b-collapse id="gestores" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <Gestores/>
            </b-card-body>
          </b-collapse>
        </b-card>

      </div>

    </div>

  </div>

</template>

<script>
import axios from "axios";
import api from '../../services/api';

import ClienteLocais from "./ClienteLocais";
import ClienteSetores from "@/pages/clientes/ClienteSetores";
import Usuarios from "@/pages/clientes/Usuarios";
import Funcoes from "./Funcoes";
import Gestores from "./Gestores";

export default {
  name: "ClienteCad",

  data() {
    return {
      telaLocal: false,
      telaSetores: false,
      acordeonLocais: false,

      form: {
        nomeComercial: null,
        cnpj: null,
        razaoSocial: null,
        logradouro: null,
        complemento: null,
        email: null,
        numero: null,
        cep: null,
        telefone1: null,
        telefone2: null,
        bairro: null,
        cidade: null,
        uf: null,
        dominioEmp: null,
        meioRecebimento: null,
        optanteSimplesNacional: null,
        contratoDataInicio: null,
        contratoDataFim: null,
        orientacoesFaturamento: null,
        listaPresenca: null,
        situacaoTributaria: null
      },
      show: true,

      meiosRecebimento: [
        'DEPÓSITO', 
        'BOLETO'
      ],

      optanteSimplesNacional: [
        'Sim', 
        'Não'
      ],

      listaPresenca: [
        'Com foto',
        'Sem foto'
      ],

      // situacaoTributaria: [
      //   'T - Tributado em São Paulo',
      //   'F - Tributado Fora de São Paulo',
      //   'A - Tributado em São Paulo, porém Isento',
      //   'B - Tributado Fora de São Paulo, porém Isento',
      //   'D - Tributado em São Paulo com isenção parcial',
      //   'M - Tributado em São Paulo, porém com indicação de imunidade subjetiva',
      //   'N - Tributado fora de São Paulo, porém com indicação de imunidade subjetiva',
      //   'R - Tributado em São Paulo, porém com indicação de imunidade objetiva',
      //   'S - Tributado fora de São Paulo, porém com indicação de imunidade objetiva',
      //   'X - Tributado em São Paulo, porém Exigibilidade Suspensa',
      //   'V - Tributado fora de São Paulo, porém Exigibilidade Suspensa',
      //   'P - Exportação de Serviços',
      //   'C - Cancelado',
      // ],

      situacaoTributaria: [],
      
      nomeTeste: 'Nelson'

    };

  },

  created() {
    const idCliente = localStorage.getItem('idCliente')

    if (idCliente) {

      this.acordeonLocais = true
      this.$store.commit('setAcordeonSetores', true)
      this.$store.commit('setAcordeonRestantes', true)
      api.get('webadmin/cliente/' + idCliente)
      .then(res => {
        this.form = res.data
        // console.log(this.form)
      })
      .catch(err => this.msg('Erro ao buscar cliente.', err, 'danger'))
      
    }

    this.getSituacaoTributaria()

  },

  methods: {
    cancelar() {
      this.$router.push('/clientes')
    },

    abriLocais() {
      this.telaLocal = true;
    },

    abriSetores() {
      this.telaSetores = true;
    },

    buscarCep() {
      console.log('cep', this.form.cep);
      if (this.form.cep.length == 9) {
        axios.get(`https://viacep.com.br/ws/${this.form.cep}/json`)
            .then(res => {
              console.log(res.data);

              const dadosCep = res.data;
              this.form.logradouro = dadosCep.logradouro;
              this.form.bairro = dadosCep.bairro;
              this.form.cidade = dadosCep.localidade;
              this.form.uf = dadosCep.uf;
            })
            .catch(err => this.msg('Erro: cep não encontrado.', err, 'danger'))
      }
    },

    getSituacaoTributaria() {

      api.get('webadmin/cliente/opcoes/situacao-tributaria')
      .then(res => {
        this.situacaoTributaria = res.data
      })
      .catch(err => this.msg('Erro: situação tributária não encontrada', err, 'danger'))

    },

    onSubmit(evt) {

      evt.preventDefault();

      const idCliente = localStorage.getItem('idCliente')

      if (idCliente) {

        this.form.idCliente = idCliente
        api.put('webadmin/clientes', this.form)
            .then(() => {
              this.msg('CLIENTE', 'ATUALIZADO COM SUCESSO.', 'success')
              //SE ATUALIZOU VAI PARA PG clienteList
              this.$router.push('/clienteList');
            })
            .catch(err => this.msg('ERRO AO ATUALIZAR CLIENTE', err.response.data, 'danger'))


      } else {

        api.post('webadmin/clientes', this.form)
            .then(res => {
              this.msg('CLIENTE', 'CADASTRADO COM SUCESSO.', 'success')

              localStorage.setItem('idCliente', res.data.id_cliente);
              this.acordeonLocais = true;

            })
            .catch(err => this.msg('ERRO AO CADASTRAR CLIENTE', err.response.data, 'danger'))
      }
    },

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    onReset(evt) {
      evt.preventDefault();
      // Redefina os valores de formulário
      this.form.nomeComercial = null;
      this.form.cnpj = null;
      this.form.razaoSocial = null;
      this.form.logradouro = null;
      this.form.email = null;
      this.form.numero = null;
      this.form.cep = null;
      this.form.telefone1 = null;
      this.form.telefone2 = null;
      this.form.telefone2 = null;
      this.form.bairro = null;
      this.form.cidade = null;
      this.form.uf = null;
      this.form.options = null;
      // this.form.checked = [];
      // Limpar o estado de validação do formulário do navegador nativo
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },

  computed: {
    acordeonSetores() {
      return this.$store.state.acordeonSetores
    },
    acordeonRestantes() {
      return this.$store.state.acordeonRestantes
    }
  },

  components: {
    ClienteLocais,
    ClienteSetores,
    Usuarios,
    Funcoes,
    Gestores

  }
};

</script>


<style scoped>

.borda {
  width: 90%;
}

@media screen and (max-width: 480px) {
  .borda {
    width: 100%;
  }
}

#clienteCad {
  position: inherit;
  display: block;
  margin-bottom: -1px;
  background-color: #f8f9fa;

  border: solid #f8f9fa;
  padding: 10px;
}

.botao-voltar {
  float: right;
  width: 170px;
}

.titulo2 {
  display: flex;
  margin-right: 0;
}

label {
  float: left;
  display: inline-block;
  margin-bottom: .5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-row > .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-1, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4,.col-lg-6,.col-md-1,.col-md-2{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

</style>