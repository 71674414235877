import axios from 'axios';
import { toastedMessage } from '../global';
import authService from './authService';

const api = axios.create({
    
    baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:4031/' : process.env.VUE_APP_URL_API
    // baseURL: 'https://testapi.eventossp.app.br/'
})

//  api.defaults.headers.common['Authorization'] = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Vzc28iOiJ3ZWJBZG1pbiIsImlkQ2xpZW50ZSI6MSwiYWRtaW4iOnRydWUsImlkVXNlciI6MSwiY29vcGVyYXRpdmEiOjMsIm1hdHJpY3VsYSI6OTYwMCwibm9tZSI6Ik1hcmNvcyBSb2JlcnRvIiwiZW1haWwiOiJrZXBhczdAZ21haWwuY29tIiwiaWF0IjoxNjA4MTI4MzUwLCJleHAiOjE2MTA3MjAzNTB9.WYeD44p7MOqFTwSeM_kwmpmaMrG0_kYtN2ORFQd_JrM'

const success = res => res
const error = err => {
    if (err.response.status == 404) {
        toastedMessage('Requisição não encontrado', 'vermelho', 5000);
    }
    if (err.response.status == 401) {
         toastedMessage('Token inválido, favor faça novamente o login', 'vermelho', 5000);
        setTimeout(() => {
            authService.logout();
        }, 1000);
    }
    // if (401 === err.response.status) {
    //     // console.log('Expulsooooo')
    //     window.location = '/login'
    // }
    return Promise.reject(err)
}

api.interceptors.response.use(success, error)

export default api;