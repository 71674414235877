// import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { JumbotronPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import firebase from './services/firebaseConnection';
import VMask from "v-mask";
import store from './stores/global';
import { FormDatepickerPlugin } from 'bootstrap-vue'
import { BIcon } from 'bootstrap-vue'
import { BFormRating } from 'bootstrap-vue'
  
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons' 
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import VueConfirmDialog from 'vue-confirm-dialog'

import { SpinnerPlugin } from 'bootstrap-vue'
//import VueMaterial from 'vue-material'  

library.add(faEnvelope)
library.add(faTrashAlt)
library.add(faFileInvoiceDollar)
library.add(faEdit)
library.add(faSquare)
library.add(faCheckSquare)
library.add(faCalculator)
library.add(faFilePdf)
library.add(faUpload)

Vue.component('font-awesome-icon', FontAwesomeIcon)

//Vue.use(VueMaterial)
  
// Vue.use(VueConfirmDialog) 
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(SpinnerPlugin)
Vue.component('b-form-rating', BFormRating)
Vue.use(FormDatepickerPlugin)
Vue.use(VMask);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('b-icon', BIcon)
Vue.use(JumbotronPlugin)

Vue.config.productionTip = false



/* onAuthStateChanged VAI VERIIFICAR AS ROTAS SE É TRUE OU FALSE */

let app
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app')
    }
})

//new Vue({
//   router,
//    render: h => h(App)
//}).$mount('#app')