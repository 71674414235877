<template>

  <div class="list-group-item">
    <div id="emissao">
      <h2 class="titulo2"> Adicionar profissionais na escala</h2>
      <b-button class="btnRight" variant="btn btn-warning" link to="/solicitacoes/verEscala"> Voltar</b-button>

      <hr>
      <form
          v-if="show"
          class="">

        <div class="form-row">


          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Matrícula</label>
            <b-form-input
                v-model="buscaMatricula"
                @keyup="filtroMatricula"
            ></b-form-input>
          </div>


          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Nome</label>
            <b-form-input
                v-model="buscaNome"
                @keyup="filtroNome"
            ></b-form-input>
          </div>


          <!-- <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Situação</label>
            <b-form-select
              v-model="situacao"
              :options="situacaoList"
            ></b-form-select>
          </div>
 -->


          <div class="linhaVermelha"></div>

        </div>

        <b-jumbotron bg-variant="light">

          <input type="checkbox" v-model="selecione_tudo" @click="select"> Selecione tudo
          <table class="table table-striped table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Matricula</th>
              <th scope="col">Nome</th>
              <th scope="col">Sexo</th>
              <th scope="col">Cidade</th>
              <th scope="col">Região</th>
              <th scope="col">Status</th>
              <th scope="col">Data</th>
            </tr>
            </thead>


            <tbody>
            <tr v-for="item in items" :key="item.id">
              <td><input type="checkbox" :value="item.id" v-model="profsSelected"></td>
              <td>{{ item.id.matricula }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.sexo }}</td>
              <td>{{ item.cidade }}</td>
              <td>{{ item.regiao }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.data_create }}</td>
            </tr>
            </tbody>
          </table>
        </b-jumbotron>

        <b-button variant="btn btn-success" @click="addProfissionais()">Confirmar</b-button>


      </form>

<!-- 
      <b-card
          class="mt-3"
          header="Resultado do Form">
        <pre class="m-0">{{ form }}</pre>
      </b-card> -->

    </div>

  </div>
</template>


<script>

// import firebase from '../services/firebaseConnection';
import api from "../../services/api";
// import axios from "axios";

export default {
  name: "Emissao",
  data() {
    return {

      buscaNome: "",
      buscaMatricula: "",

      selecione_tudo: false,
      profsSelected: [],

      form: {
        cliente: "",
        unidade: "",
        setor: "",
        periodo: "",
        selecione: null
      },

      selected: "selecione",
      situacaoList: ['todos','cooperados','pré-cadastro'],
      situacao: 'pré-cadastro',

      items: [],

      checkbox: [],
      show: true
    };
  },

  created() {
    // this.getProfsPreCadastro()
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    select() {
      this.selected4 = [];
      if (!this.selecione_tudo) {
        for (let i in this.items) {
          this.selected4.push(this.items[i].id);

        }
      }
    },

    // getProfsPreCadastro() {

    //   api.get('webadmin/solicitacoes/pre-cadastro')
    //       .then(res => {
    //         // console.log('dddddddddddddddd',res.data)
    //         this.items = res.data
    //       })
    //       .catch(err => this.msg('ERRO AO BUSCAR PROFISSIONAIS', err.response.data, 'danger'))

    // },

    filtroNome() {
      this.items = []
      this.profsSelected = []
      // if (this.buscaNome.length == 0) this.getCooperadosFiltro() 

      if (this.buscaNome.length > 2) {
        this.getCooperadosFiltro("nome", this.buscaNome.toUpperCase());
      }
    },
    filtroMatricula() {
      this.items = []
      this.profsSelected = []
      // if (this.buscaMatricula.length == 0) this.getCooperadosFiltro() 

      if (this.buscaMatricula.length > 2) {
        this.getCooperadosFiltro("matricula", this.buscaMatricula.toString());
      }
    },

    getCooperadosFiltro(filtro, valor) {
      // this.items = []

      // console.log('aaaaaaaaaaaa',this.situacao)

      api
        .get(`webadmin/solicitacoes/cooperados-filtro/${this.situacao}/${filtro}/${valor}`)
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
        })
        .catch(err => {
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });
    },

    addProfissionais() {
      // console.log(this.profsSelected)
      const localInfoEscala = localStorage.getItem('solicitacao')
      const infoEscala = JSON.parse(localInfoEscala)
      // console.log('hehehe',infoEscala)


      const dados = {
        id_evento: infoEscala.idEvento,
        id_escala: infoEscala.id,
        id_funcao: infoEscala.idFuncao,
        id_user: infoEscala.idGestor,
        profs: this.profsSelected
      }


      api.post('webadmin/solicitacoes/addprofs-escala', dados)
          .then(() => {
            this.msg('ESCALA', 'CONFIRMADO COM SUCESSO.', 'success')
            this.$router.push('/solicitacoes/verEscala')
          })
          .catch(err => this.msg('ERRO AO CONFIRMAR', err.response.data, 'danger'))


    },

  }
};

</script>


<style scoped>


#emissao {
  position: inherit;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, .125);
  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
}


@media screen and (max-width: 480px) {
  .bloco1 {
    width: 100%;
    flex-direction: column;
  }
}

.btn-emissao {
  display: inline-block;
  margin-left: 10px;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-emissao {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-emissao:hover {
  color: #fff;
  background-color: #8c8a8a;
  border-color: #8c8a8a;
}

.titulo2 {
  display: flex;
  margin-right: 0;

}

.linhaVermelha {
  width: 100%;
  border-bottom: 1px solid #f21d1d !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

label {
  float: left;
  display: inline-block;
  margin-bottom: .5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-3, .col-md-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-bottom: -200px;
}

.list-group-item {
  position: inherit;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;

}

@media screen and (max-width: 480px) {
  .list-group-item {
    display: block;
    padding: 6px 6px;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  display: table;
  overflow-x: auto;
}

@media screen and (max-width: 576px) {
.table {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    }
}

.data-v-email {
  width: 4.7rem;
}


</style>